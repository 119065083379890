import React from 'react';
import {
  Paper,
  LinearProgress,
  CircularProgress,
  Dialog,
  DialogContent,
  DialogTitle
} from "@material-ui/core";
import { createTheme, ThemeProvider } from "@material-ui/core/styles";
import Alert from "@material-ui/lab/Alert"
import IconButton from '@material-ui/core/IconButton';
import CloseIcon from '@material-ui/icons/Close';
import NavigationMenu from "../../dashboard/src/NavigationMenu.web";
import "../assets/css/ViewUserProfile.css";
import Customisableuserprofiles2Controller, { Props } from './Customisableuserprofiles2Controller';
import { Formik, Field, ErrorMessage } from 'formik';
import * as Yup from 'yup';
import i18nJs from "../../../components/src/TranslateLanguage";
import moment from 'moment';
import DatePicker from "react-datepicker";
import "react-datepicker/dist/react-datepicker.css";

const baseURL = require("../../../framework/src/config.js").baseURL
const theme = createTheme({
  palette: {
    primary: {
      main: "#0000ff",
      contrastText: "#fff",
    },
  },
});

const validationSchema = Yup.object({
  lname: Yup.string().matches(/^[A-Za-z ]*$/, 'Please enter last name.').required('Last name is required.'),
  fname: Yup.string().matches(/^[A-Za-z ]*$/, 'Please enter valid name.').required('First name is required.'),
  phno: Yup.string()
  .matches(/^(\+)[0-9 ]{9,14}$/, 'Please enter valid number.')
  .required('Number is required.'),
  gender: Yup.string().required('Gender is required.'),
  country: Yup.string().matches(/^[A-Za-z ]*$/, 'Please enter valid country.').required('Country is required.'),
  date: Yup.string().required('DOB is required.'),
  occupation: Yup.string().matches(/^[A-Za-z ]*$/, 'Please enter valid occupation.').required('Occupation is required.'),
  email: Yup.string()
  .email('Invalid email address.')
  .matches(
  /^[a-z0-9._%+-]+@[a-z0-9.-]+\.[a-z]{2,4}$/, // Replace with your desired pattern
  'Please enter valid email address.'
  )
    .required('Email is required.'),
  city: Yup.string().matches(/^[A-Za-z ]*$/, 'Please enter valid city.').required('City is required.')
});


export default class EditUserProfile extends Customisableuserprofiles2Controller {
  [x: string]: any;

  constructor(props: Props) {
    super(props);
  }

  modalVisible = () => {
    return (
      <Dialog maxWidth={'xs'} fullWidth aria-labelledby="simple-dialog-title" open={this.state.modalVisible} className={i18nJs.locale === 'ar' ? "update_profile_model_arabic" : "update_profile_model"}>
        <DialogTitle id="simple-dialog-title" className='modal-header'> 
        <button data-test-id="close_button_modal" onClick={() => this.onClickModelClose() } type="button" className="close">
               <span>×</span>
             </button></DialogTitle>
             <DialogContent className='dialog-content-body'>
             <div className="modal-icon">
              <svg xmlns="http://www.w3.org/2000/svg" height="1em" viewBox="0 0 448 512">
                 <path d="M304 128a80 80 0 1 0 -160 0 80 80 0 1 0 160 0zM96 128a128 128 0 1 1 256 0A128 128 0 1 1 96 128zM49.3 464H398.7c-8.9-63.3-63.3-112-129-112H178.3c-65.7 0-120.1 48.7-129 112zM0 482.3C0 383.8 79.8 304 178.3 304h91.4C368.2 304 448 383.8 448 482.3c0 16.4-13.3 29.7-29.7 29.7H29.7C13.3 512 0 498.7 0 482.3z" />
              </svg>
             </div>
             <h5>{i18nJs.t('PROFILE_UPDATED')}</h5>
             <p>{i18nJs.t('The_required_user_profile_details_were_successfully_updated')}</p>
           </DialogContent>
      </Dialog>
    )
  }

  deleteModalVisible = () => {
    return(<Dialog maxWidth={'xs'} fullWidth aria-labelledby="simple-dialog-title" open={this.state.deleteModalVisible}>
      <div className="modal-block none-modal">
       <div className="modal-dialog">
         <div className="modal-content">
           <div className="modal-header">
             <button data-test-id="close_button_modal_deleteModalVisible" onClick={() => this.onClicDeleteModalVisible()} type="button" className="close">
               <span>×</span>
             </button>
           </div>
           <div className="modal-body">
             <div className="modal-icon">
              <svg xmlns="http://www.w3.org/2000/svg" width="26" height="26" viewBox="0 0 26 26" fill="none"><path fillRule="evenodd" clipRule="evenodd" d="M16.0228 3.56417L16.792 4.33333H19.5003C20.0962 4.33333 20.5837 4.82083 20.5837 5.41667C20.5837 6.0125 20.0962 6.5 19.5003 6.5H6.50033C5.90449 6.5 5.41699 6.0125 5.41699 5.41667C5.41699 4.82083 5.90449 4.33333 6.50033 4.33333H9.20866L9.97783 3.56417C10.1728 3.36917 10.4545 3.25 10.7362 3.25H15.2645C15.5462 3.25 15.8278 3.36917 16.0228 3.56417ZM6.50033 20.5833C6.50033 21.775 7.47533 22.75 8.66699 22.75H17.3337C18.5253 22.75 19.5003 21.775 19.5003 20.5833V9.75C19.5003 8.55833 18.5253 7.58333 17.3337 7.58333H8.66699C7.47533 7.58333 6.50033 8.55833 6.50033 9.75V20.5833ZM9.75033 9.75H16.2503C16.8462 9.75 17.3337 10.2375 17.3337 10.8333V19.5C17.3337 20.0958 16.8462 20.5833 16.2503 20.5833H9.75033C9.15449 20.5833 8.66699 20.0958 8.66699 19.5V10.8333C8.66699 10.2375 9.15449 9.75 9.75033 9.75Z" fill="#37657F"/></svg>
             </div>
             <h5>{i18nJs.t('Account Delete')}</h5>
             <p>{i18nJs.t('Are you sure want to delete this account')}</p>
           </div>
           <div className="modal-footer profile-button">
             <button data-test-id="account_delete__modal" onClick={() => this.onClicDeleteModalVisible()} type="button" className="btn button-outline-profile-edit" data-dismiss="modal">{i18nJs.t('Cancel Delete')}</button>
             <button type="button" className="btn button-red button-edit" data-test-id="delete-account-button" disabled={this.state.deleteAccountLoading} onClick={() => this.deleteAccount()}>{this.state.deleteAccountLoading ? <CircularProgress size={30} className='text-white'/> : i18nJs.t('Delete Account')}</button>
           </div>
         </div>
       </div>
     </div>
    </Dialog>)
  }

  uploadFileModalVisible = () => {
    return (
    <Dialog maxWidth="lg" fullWidth open={this.state.uploadFileModalVisible}>
    <div className={`upload-modal-block ${i18nJs.locale === 'ar' ? "profile_upload_modal_arabic" : "profile_upload_modal"}`}>
      <div className="modal-dialog">
        <div className="modal-content">
          <div className="modal-header p-0">
            <div className="block-heading">
              <h3>{i18nJs.t('Upload Profile Image')}</h3>
              {/* <p>{i18nJs.t('Upload the profile image from your file manager to show facial recognition')}</p> */}
            </div>
            <button data-test-id="close_profile_modal_uploadFileModalVisible" onClick={() => this.onCloseFileUploder()} type="button" className="close">
              <span>×</span>
            </button>
          </div>
          <div className="modal-body">
            <div className="darg-block">
              <div className="upload-btn-wrapper">
                <button
                  className="button drop-button"
                  data-test-id="drag_image"
                  onDrop={this.handleDrop}
                  onDragOver={this.handleDragOver}
                  onDragEnter={this.handleDragEnter}
                  onDragLeave={this.handleDragLeave}
                  onClick={this.handleClick}
                >
                  {this.state.file ? (
                    <img src={URL.createObjectURL(this.state.file)} />
                  ) : (
                    <>
                      <svg xmlns="http://www.w3.org/2000/svg" width="24" height="24" viewBox="0 0 24 24" fill="none">
                        <path d="M12 15.575C11.7167 15.575 11.4793 15.479 11.288 15.287C11.096 15.0957 11 14.8583 11 14.575V7.425L9.125 9.3C8.925 9.5 8.69167 9.6 8.425 9.6C8.15833 9.6 7.91667 9.49167 7.7 9.275C7.5 9.075 7.40433 8.83733 7.413 8.562C7.421 8.28733 7.51667 8.05833 7.7 7.875L11.3 4.275C11.4 4.175 11.5083 4.104 11.625 4.062C11.7417 4.02067 11.8667 4 12 4C12.1333 4 12.2583 4.02067 12.375 4.062C12.4917 4.104 12.6 4.175 12.7 4.275L16.3 7.875C16.5 8.075 16.5957 8.31233 16.587 8.587C16.579 8.86233 16.4833 9.09167 16.3 9.275C16.1 9.475 15.8627 9.579 15.588 9.587C15.3127 9.59567 15.075 9.5 14.875 9.3L13 7.425V14.575C13 14.8583 12.9043 15.0957 12.713 15.287C12.521 15.479 12.2833 15.575 12 15.575ZM6 19.575C5.45 19.575 4.97933 19.3793 4.588 18.988C4.196 18.596 4 18.125 4 17.575V15.575C4 15.2917 4.09567 15.054 4.287 14.862C4.479 14.6707 4.71667 14.575 5 14.575C5.28333 14.575 5.521 14.6707 5.713 14.862C5.90433 15.054 6 15.2917 6 15.575V17.575H18V15.575C18 15.2917 18.096 15.054 18.288 14.862C18.4793 14.6707 18.7167 14.575 19 14.575C19.2833 14.575 19.5207 14.6707 19.712 14.862C19.904 15.054 20 15.2917 20 15.575V17.575C20 18.125 19.8043 18.596 19.413 18.988C19.021 19.3793 18.55 19.575 18 19.575H6Z" fill="#1C1B1F" />
                      </svg>
                      {i18nJs.t('Click to upload or drag & drop Maximum file size 5 MB')}
                    </>
                  )}
                </button>
                <input type="file" accept="image/*" value={this.state.filesUploadFlag} title="" name="myfile" test-id="handleimage" onChange={(event: React.ChangeEvent<HTMLInputElement>) => {
                  this.handleFileChangeProfile(event)
                }} />
                {!this.state.profileImages && <span className='error-text' style={{ color: 'red' }}>
                  {this.state.imgError === true ? <span className='error'>{i18nJs.t('Image is Required')}</span> : ""}
                </span>}
              </div>
              <div className="image-block">
                <p><svg xmlns="http://www.w3.org/2000/svg" width="24" height="24" viewBox="0 0 24 24" fill="none">
                  <mask id="mask0_528_15091" maskUnits="userSpaceOnUse" x="0" y="0" width="24" height="24">
                    <rect width="24" height="24" fill="#D9D9D9" />
                  </mask>
                  <g mask="url(#mask0_528_15091)">
                    <path d="M9 12.5H10V10.5H11C11.2833 10.5 11.5208 10.4042 11.7125 10.2125C11.9042 10.0208 12 9.78333 12 9.5V8.5C12 8.21667 11.9042 7.97917 11.7125 7.7875C11.5208 7.59583 11.2833 7.5 11 7.5H9V12.5ZM10 9.5V8.5H11V9.5H10ZM13 12.5H15C15.2833 12.5 15.5208 12.4042 15.7125 12.2125C15.9042 12.0208 16 11.7833 16 11.5V8.5C16 8.21667 15.9042 7.97917 15.7125 7.7875C15.5208 7.59583 15.2833 7.5 15 7.5H13V12.5ZM14 11.5V8.5H15V11.5H14ZM17 12.5H18V10.5H19V9.5H18V8.5H19V7.5H17V12.5ZM8 18C7.45 18 6.97917 17.8042 6.5875 17.4125C6.19583 17.0208 6 16.55 6 16V4C6 3.45 6.19583 2.97917 6.5875 2.5875C6.97917 2.19583 7.45 2 8 2H20C20.55 2 21.0208 2.19583 21.4125 2.5875C21.8042 2.97917 22 3.45 22 4V16C22 16.55 21.8042 17.0208 21.4125 17.4125C21.0208 17.8042 20.55 18 20 18H8ZM4 22C3.45 22 2.97917 21.8042 2.5875 21.4125C2.19583 21.0208 2 20.55 2 20V6H4V20H18V22H4Z" fill="#1C1B1F" />
                  </g>
                </svg>{this.state?.file?.name}</p>
              </div>
            </div>
            {this.state.open ?
              <Alert action={
                <IconButton
                  test-id="discard_profile_modal_submit"
                  aria-label="close"
                  color="inherit"
                  size="small"
                  onClick={() => {
                    this.setState({ open: false })
                  }}
                >
                  <CloseIcon fontSize="inherit" />
                </IconButton>
              }
                severity="error">
                {i18nJs.t('Sorry, an error has occurred while uploading "Raffels image".Please try again')}
              </Alert> : null
            }
            {this.state.file && (
              <div className="process-block">
                <LinearProgress variant="determinate" value={this.state.uploadProgress} className='process-bar-clr' />
                <div className="process-info">
                  <div className="process-info-image">
                    <img src={URL.createObjectURL(this.state.file)} />
                  </div>
                  <div className="process-info-content">
                    <h6>{this.state.file.name}</h6>
                    <span>{this.state.uploadProgress}% uploaded</span>
                  </div>
                  <div onClick={() => this.onClickFileClear()} className="process-block-close">
                    <svg xmlns="http://www.w3.org/2000/svg" height="1em" viewBox="0 0 384 512"><path d="M342.6 150.6c12.5-12.5 12.5-32.8 0-45.3s-32.8-12.5-45.3 0L192 210.7 86.6 105.4c-12.5-12.5-32.8-12.5-45.3 0s-12.5 32.8 0 45.3L146.7 256 41.4 361.4c-12.5 12.5-12.5 32.8 0 45.3s32.8 12.5 45.3 0L192 301.3 297.4 406.6c12.5 12.5 32.8 12.5 45.3 0s12.5-32.8 0-45.3L237.3 256 342.6 150.6z" /></svg>
                  </div>
                </div>
              </div>
            )}
          </div>
        </div>
        <div className="modal-footer profile-button">
          <button data-test-id="discard_profile_modal" onClick={() => this.onClickDiscardModel()} type="button" className="btn button-outline-profile">{i18nJs.t('Discard')}</button>
          <button data-test-id="discard_profile_modal_submit" type="button" className="btn button" disabled={this.state.profileImagesFlag || !this.state.file} onClick={() => { this.editProfileDetailsImages()}}>{this.state.profileImagesFlag ? <CircularProgress size={30} className='text-white'/> : i18nJs.t('Upload now')}</button>
        </div>
      </div>
    </div>
    </Dialog>)
  }

  renderImageName = (URL:string) => {
    const aray = URL && URL.split("/");
    return aray && aray[aray?.length - 1]
  }

  edituserForm = () => {
    return <Formik
    initialValues={{
      fname:  this.state.userData?.attributes?.first_name || "",
      lname: this.state.userData?.attributes?.last_name || "",
      email:  this.state.userData?.attributes?.email || "",
      phno:  this.state.userData?.attributes?.full_phone_number || this.state.userData?.attributes?.phone_number || "",
      date:  moment(this.state.userData?.attributes?.date_of_birth || new Date(), "DD-MM-YYYY").toDate(),
      gender:  this.state.userData?.attributes?.gender || "",
      country:  this.state.userData?.attributes?.country || "",
      city:  this.state.userData?.attributes?.city || "",
      card:  null,
      occupation: this.state.userData?.attributes?.occupation || "",
    }}
    data-test-id="submit-button"
    onSubmit={(values) => {
      if(this.state.onSubmitFlag){
        this.editProfileDetails(values)
      }
    }}
    validationSchema={validationSchema}
  >
    {({ handleSubmit, setFieldValue, values }) => {
      return <form onSubmit={handleSubmit}>
        <div className="profile-block-heading">
          <h3>{i18nJs.t('Basic Information')}</h3>
        </div>
        <div className="profile-block-content">
          <div className="profile-form-block">
            <div className="form-row">
              <div className="form-group">
                <label htmlFor="fname">{i18nJs.t('First Name')}*</label>
                <Field  type="text" id="fname" name="fname" placeholder="" disabled={this.state.editProfile}/>
                <span className='error-text' data-test-id="checkingid" style={{ color: 'red' }}>
                  <ErrorMessage name="fname" component="div" className="error helloclass" />
                </span>
              </div>
              <div className="form-group" id='checkcheckid'>
                <label htmlFor="lname" className='heytest'>{i18nJs.t('Last Name')}*</label>
                <Field type="text" data-test-id="boletotest" id="lname" name="lname" placeholder="" disabled={this.state.editProfile}/>
                <span id='checkingpurpos' className='error-text' style={{ color: 'red' }}>
                  <ErrorMessage name="lname" component="div" className="error hellotestcase" />
                </span>
              </div>
            </div>
            <div className="form-row">
              <div className="form-group">
                <label htmlFor="mail">{i18nJs.t('Email')}*</label>
                <Field type="email" id="mail" name="email" placeholder="" disabled={true}/>
                <span className='error-text' style={{ color: 'red' }}>
                  <ErrorMessage name="email" component="div" className="error" />
                </span>
              </div>
              <div className="form-group">
                <label htmlFor="no">{i18nJs.t('Phone Number')}*</label>
                <Field type="text" id="no" name="phno" placeholder="" disabled={this.state.editProfile}/>
                <span className='error-text' style={{ color: 'red' }}>
                  <ErrorMessage name="phno" component="div" className="error" />
                </span>
              </div>
            </div>
            <div className="form-row">
              <div className="form-group date_picker_input">
                <label htmlFor="DOBdatepicker">{i18nJs.t('Date of birth')}*</label>
                <DatePicker  
                  selected={values.date}
                  id="DOBdatepicker"
                  onChange={(date: Date | null) => setFieldValue('date', date)}
                  dateFormat="dd-MM-yyyy"   
                />
                <span id='checkcheckid' className='error-text' style={{ color: 'red' }}>
                  <ErrorMessage name="date" component="div" className="error helloooclass" />
                </span>
              </div>
              <div className="form-group" data-test-id="heytest">
                <label htmlFor="gender">{i18nJs.t('Gender')}*</label>
                <Field className="fakeclassss" data-test-id="selectgender" initialValue="" as="select" id="gender" name="gender" disabled={this.state.editProfile}>
                  <option disabled value="">--{i18nJs.t('Select Gender')}--</option>
                  <option value="male">{i18nJs.t('Male')}</option>
                  <option value='female'>{i18nJs.t('Female')}</option>
                </Field>
                <span data-test-id="hehehehe" className='error-text' style={{ color: 'red' }}>
                  <ErrorMessage name="gender" component="div" className="error ppppp" />
                </span>
              </div>
            </div>
            <div className="form-row">
              <div className="form-group" data-test-id="testtestid">
                <label htmlFor="country" id='hellooooid'>{i18nJs.t('Country')}*</label>
                <Field type="text" id="country" name="country" placeholder="" disabled={this.state.editProfile}/>
                <span className='error-text checkingchecking' style={{ color: 'red' }}>
                  <ErrorMessage name="country" component="div" className="error" />
                </span>
              </div>
              <div className="form-group" id="errorkiid">
                <label htmlFor="city" className='lablekaclass'>{i18nJs.t('City')}*</label>
                <Field type="text" id="city" name="city" placeholder="" disabled={this.state.editProfile}/>
                <span data-test-id='heyidddd' className='error-text' style={{ color: 'red' }}>
                  <ErrorMessage name="city" component="div" className="error verysomeerror" />
                </span>
              </div>
            </div>
            <div className="form-row">
              <div className="form-group">
                <label htmlFor="upload">{i18nJs.t('Identification Card')}* <span>({i18nJs.t('Passport no')}., {i18nJs.t('National')} &amp;
                {i18nJs.t('Resident ID')} - {i18nJs.t('Iqama')})*</span>
                </label>
                <div className="upload-btn">
                  <div className="upload-btn-wrapper">
                    <button className="button">{this.state.uploaded_documents ? this.state.uploaded_documents.name : this.renderImageName(this.state.userData?.attributes?.identification_card?.url)}</button>
                    <Field type="file"
                      data-test-id="uploadField"
                      accept="image/*"
                      id="card" name="card"
                      onChange={(event: React.ChangeEvent<HTMLInputElement>) => {
                        this.handleFileChange(event)
                      }} disabled={this.state.editProfile} title="" />
                    {!this.state.uploaded_documents && <span className='error-text' style={{ color: 'red' }}>
                      {this.state.imgError === true && !this.state.userData?.attributes?.identification_card?.url ? <span className='error'>Image is Required</span> : ""}
                    </span>}
                    <svg xmlns="http://www.w3.org/2000/svg" height="1em" viewBox="0 0 448 512"><path d="M246.6 9.4c-12.5-12.5-32.8-12.5-45.3 0l-128 128c-12.5 12.5-12.5 32.8 0 45.3s32.8 12.5 45.3 0L192 109.3V320c0 17.7 14.3 32 32 32s32-14.3 32-32V109.3l73.4 73.4c12.5 12.5 32.8 12.5 45.3 0s12.5-32.8 0-45.3l-128-128zM64 352c0-17.7-14.3-32-32-32s-32 14.3-32 32v64c0 53 43 96 96 96H352c53 0 96-43 96-96V352c0-17.7-14.3-32-32-32s-32 14.3-32 32v64c0 17.7-14.3 32-32 32H96c-17.7 0-32-14.3-32-32V352z" /></svg>
                  </div>
                </div>
              </div>
              <div className="form-group">
                <label htmlFor="occupation">{i18nJs.t('Occupation')}*</label>
                <Field type="text" id="occupation" name="occupation" placeholder="" disabled={this.state.editProfile}/>
                <span className='error-text' style={{ color: 'red' }}>
                  <ErrorMessage name="occupation" component="div" className="error" />
                </span>
              </div>
            </div>
          </div>
        </div>
        <div className="profile-block-heading flex-direction-column align-content-start align-items-start">
          <h3 className='mb-0'>{i18nJs.t('Account')}</h3>
          <h4 className='mt-0 mb-05 f-400'>{this.state.userData?.attributes?.email}</h4>
        </div>
        <button
            type='button'
            data-test-id="saveprofile-modal-change-password"
            className="btn button-outline-profile-edit"
            onClick={() => this.onClickProfileEdit()}>{i18nJs.t('Change password')}</button>
        <div className="profile-button">
          <button type='button' data-test-id="delete_modal" onClick={() => this.onClicDeleteModalVisibleOn()} className="btn button-outline-profile button-red-outline">{i18nJs.t('Delete Account')}
            <svg xmlns="http://www.w3.org/2000/svg" width="24" height="24" viewBox="0 0 24 24">
              <path fillRule="evenodd" clipRule="evenodd" d="M14.79 3.29L15.5 4H18C18.55 4 19 4.45 19 5C19 5.55 18.55 6 18 6H6C5.45 6 5 5.55 5 5C5 4.45 5.45 4 6 4H8.5L9.21 3.29C9.39 3.11 9.65 3 9.91 3H14.09C14.35 3 14.61 3.11 14.79 3.29ZM6 19C6 20.1 6.9 21 8 21H16C17.1 21 18 20.1 18 19V9C18 7.9 17.1 7 16 7H8C6.9 7 6 7.9 6 9V19ZM9 9H15C15.55 9 16 9.45 16 10V18C16 18.55 15.55 19 15 19H9C8.45 19 8 18.55 8 18V10C8 9.45 8.45 9 9 9Z" />
            </svg>
          </button>
          {this.state.editProfile ? (
            <button
            type='button'
            data-test-id="saveprofile_modal_edit_profile"
            className="btn button-green save-modal-block"
            onClick={() => this.onClickChangeDisabled()}>{i18nJs.t('Edit Profile')}</button>
          ) : (
            <button
            data-test-id="saveprofile-modal-save-changes"
            type='submit'
            className="btn button-green save-modal-block"
            onClick={
              () => {
                this.setState({onSubmitFlag:true})
                if (this.state.uploaded_documents) {
                  this.setState({ imgError: false })
                } {
                  this.setState({ imgError: true })
                }
              }
            }>{i18nJs.t('Save changes')}</button>
          )}
          
            
        </div>
      </form>
    }}
  </Formik>
  }

  render() {
    return (
      <ThemeProvider theme={theme}>
        <NavigationMenu id={this.props.id} navigation={this.props.navigation} title='User Profile'>
          {this.state.isloading ? 
          (<div className="home-category-block-content-norecord-loader"><CircularProgress style={{color:'#205e83'}}/></div>)
           : 
           (
          <Paper style={{ width: "100%", overflow: "hidden" }} >
            <div className="content-wrapper">
              <div className="profile-block">
                <h3>{i18nJs.t('Profile Picture')}</h3>
                <div className="profile-info">
                  <div className="profile-info-image">
                    {this.state.userData?.attributes?.profile_picture?.url && <img src={baseURL + this.state.userData?.attributes?.profile_picture?.url} alt='profile-image'/>}
                  </div>
                  <form>
                    <div className="upload-file-btn">
                      <div data-test-id="upload_image_modal" onClick={() => this.onClickuploadFileModalVisibleOpen()} className="upload-file">
                        <svg xmlns="http://www.w3.org/2000/svg" height="1em" viewBox="0 0 448 512"><path d="M246.6 9.4c-12.5-12.5-32.8-12.5-45.3 0l-128 128c-12.5 12.5-12.5 32.8 0 45.3s32.8 12.5 45.3 0L192 109.3V320c0 17.7 14.3 32 32 32s32-14.3 32-32V109.3l73.4 73.4c12.5 12.5 32.8 12.5 45.3 0s12.5-32.8 0-45.3l-128-128zM64 352c0-17.7-14.3-32-32-32s-32 14.3-32 32v64c0 53 43 96 96 96H352c53 0 96-43 96-96V352c0-17.7-14.3-32-32-32s-32 14.3-32 32v64c0 17.7-14.3 32-32 32H96c-17.7 0-32-14.3-32-32V352z" /></svg>
                        <span className='place-text'>{i18nJs.t('Upload picture')}</span>
                      </div>
                      <button type='button' className='bin-wrapper' data-test-id="delete-picture-button" disabled={this.state.userData?.attributes?.profile_picture?.url ? false : true} onClick={() => this.deletePicture()}>
                        <svg xmlns="http://www.w3.org/2000/svg" width="20" height="20" viewBox="0 0 20 20" fill="none">
                          <path fillRule="evenodd" clipRule="evenodd" d="M12.3243 2.74167L12.916 3.33333H14.9993C15.4577 3.33333 15.8327 3.70833 15.8327 4.16667C15.8327 4.625 15.4577 5 14.9993 5H4.99935C4.54102 5 4.16602 4.625 4.16602 4.16667C4.16602 3.70833 4.54102 3.33333 4.99935 3.33333H7.08268L7.67435 2.74167C7.82435 2.59167 8.04102 2.5 8.25768 2.5H11.741C11.9577 2.5 12.1743 2.59167 12.3243 2.74167ZM4.99935 15.8333C4.99935 16.75 5.74935 17.5 6.66602 17.5H13.3327C14.2493 17.5 14.9993 16.75 14.9993 15.8333V7.5C14.9993 6.58333 14.2493 5.83333 13.3327 5.83333H6.66602C5.74935 5.83333 4.99935 6.58333 4.99935 7.5V15.8333ZM7.49935 7.5H12.4993C12.9577 7.5 13.3327 7.875 13.3327 8.33333V15C13.3327 15.4583 12.9577 15.8333 12.4993 15.8333H7.49935C7.04102 15.8333 6.66602 15.4583 6.66602 15V8.33333C6.66602 7.875 7.04102 7.5 7.49935 7.5Z" fill="#DC2626" />
                        </svg>
                        <span>{i18nJs.t('Delete picture')}</span>
                      </button>
                    </div>
                  </form>
                </div>
              </div>
              <div className="profile-conetnt">
                {this.edituserForm()}
              </div>

              {this.modalVisible()}
              {this.deleteModalVisible()}
            </div>

            {this.uploadFileModalVisible()}


          </Paper>
          )}
        </NavigationMenu>
      </ThemeProvider>
    )
  }
}