import { IBlock } from "../../../framework/src/IBlock";
import { Message } from "../../../framework/src/Message";
import { BlockComponent } from "../../../framework/src/BlockComponent";
import MessageEnum, {
  getName,
} from "../../../framework/src/Messages/MessageEnum";
import { runEngine } from "../../../framework/src/RunEngine";

// Customizable Area Start
import React from "react";
import { getStorageData } from "../../../framework/src/Utilities";
// Customizable Area End

export const configJSON = require("./config");

export interface Props {
  navigation: any;
  id: string;
  // Customizable Area Start
  // Customizable Area End
}

interface S {
  // Customizable Area Start
  anchorEl:any;
  checked:string;
  // checked1:string;
  result: any;
  getAnswer:boolean;
  authToken:string;
  assessmentResultList:any;
  assessmentSubjectiveList:any;
  assessmentSubjectiveQuestions:any;
  NewMarks:any;
  studentSubjectiveMarks:any,
  student_scores:any,
  feedbackInput:any,
  isLoading:boolean;
  serchAssementValue:string;
  assessmentSubjective:any;
  errors:any;
  divRef: React.MutableRefObject<HTMLDivElement | null>;
  searchHistoryData:string[];
  searchValue:string;
  searchTerm:string;
  searchHistory:boolean;
  filteredCompletedCourses: any;
  filteredCompleteStore: any;
  currentPage: number;
  rowsPerPage: number;
  // Customizable Area End
}

interface SS {
  id: any;
  // Customizable Area Start
  // Customizable Area End
}

export default class AssessmenttestResultController extends BlockComponent<
  Props,
  S,
  SS
> {
  // Customizable Area Start
  getApiResultAssessementApiCallId:any;
  getApiResultSubjectiveAssessementApiCallId:any;
  getApiResultSubjectiveQuestionsApiCallId:any;
  studentSubjectiveMarksApiCallId:any;
  studentSubjectiveFeedbackApiCallId:any;
  // Customizable Area End

  constructor(props: Props) {
    super(props);
    this.receive = this.receive.bind(this);

    // Customizable Area Start
    this.subScribedMessages = [
      getName(MessageEnum.AccoutLoginSuccess),
      // Customizable Area Start
      getName(MessageEnum.SessionResponseMessage),
      getName(MessageEnum.RestAPIResponceMessage)
      // Customizable Area End
    ];

    this.state = {
      // Customizable Area Start
      anchorEl:null,
      checked:"sortBy",
      // checked1:true,
      result: 'Pass',
      getAnswer:false,
      authToken:"",
      assessmentResultList:[],
      assessmentSubjectiveList:[],
      assessmentSubjectiveQuestions:[],
      NewMarks:"",
      studentSubjectiveMarks:[],
      student_scores:[],
      feedbackInput:"",
      isLoading:false,
      serchAssementValue:"",
      assessmentSubjective:[],
      errors:{},
      searchHistoryData:[],
      searchValue:"",
      searchTerm:"",
      searchHistory:false,
      divRef: React.createRef(),
      filteredCompletedCourses:[],
      filteredCompleteStore: [],
      currentPage: 1,
      rowsPerPage: 6,
      // Customizable Area End
    };
    runEngine.attachBuildingBlock(this as IBlock, this.subScribedMessages);

    // Customizable Area Start
    // Customizable Area End
  }

  async receive(from: string, message: Message) {
    runEngine.debugLog("Message Recived", message);
    // Customizable Area Start
    if (getName(MessageEnum.RestAPIResponceMessage) === message.id) {
      let apiRequestCallId = message.getData(
        getName(MessageEnum.RestAPIResponceDataMessage)
      );
      let responseJson = message.getData(
        getName(MessageEnum.RestAPIResponceSuccessMessage)
      );
      
if (apiRequestCallId === this.getApiResultSubjectiveQuestionsApiCallId && responseJson) {
  this.setState({assessmentSubjectiveQuestions:responseJson,isLoading:false})
}
if (apiRequestCallId === this.studentSubjectiveMarksApiCallId && responseJson) {
  this.props.navigation.navigate('AssessmenttestResult')
  this.setState({isLoading:false})
}
if (apiRequestCallId === this.studentSubjectiveFeedbackApiCallId && responseJson) {
        this.setState({ feedbackInput: "" });
}

this.responseData(apiRequestCallId, responseJson)
}
    
    // Customizable Area End
  }

  // Customizable Area Start
  responseData = (apiRequestCallId:any, responseJson:any) => {
    (apiRequestCallId === this.getApiResultAssessementApiCallId && responseJson.data) && this.onCallFunctionList(responseJson);
    (apiRequestCallId === this.getApiResultSubjectiveAssessementApiCallId && responseJson) && this.onCallFunctionSubjectiveList(responseJson)
  }
  

  onCallFunctionList = (responseJson:any) => {
    let arrayOfObjects = responseJson.data.sort((asss:any, bsss:any) => bsss.id - asss.id);
    this.setState({assessmentResultList:arrayOfObjects,isLoading:false})
    }

  onCallFunctionSubjectiveList = (responseJson:any) => {
    let arrayOfObjects = responseJson.sort((asss:any, bsss:any) => bsss.id - asss.id);
    this.setState({assessmentSubjectiveList:arrayOfObjects,isLoading:false})
  }

  async componentDidMount() {
    super.componentDidMount();
    let authToken = await getStorageData("token")
    this.setState({ authToken: authToken }, () => {
      this.getApiAssessementResult()
      this.getApiSubjectiveAssessementResult()
      this.getApiSubjectiveQuestinsResult()
    });
    document.addEventListener("mousedown", this.handleOutsideClickLectureAssesment)
  }

  handleClick = (event:any) => {
    this.setState({ anchorEl : event.currentTarget });
  };

  handleClose = () => {
    this.setState({ anchorEl : null });
  };

  handleChange = (value:string) => {
    this.setState({ checked: value ,currentPage:1 });
  };

  handleChange1 = (event: React.ChangeEvent<{ value: unknown }>) => {
    this.setState({ result: event.target.value as string });
  };

  onClickGoBack = () => {
    this.props.navigation.goBack()
  }

  getApiAssessementResult = () => {
    this.setState({isLoading:true})
    const header = {
      "Content-Type": configJSON.categoryApiContentType,
      token: this.state.authToken
    };
    const requestMessage = new Message(
      getName(MessageEnum.RestAPIRequestMessage)
    );
    this.getApiResultAssessementApiCallId = requestMessage.messageId;
    requestMessage.addData(
      getName(MessageEnum.RestAPIResponceEndPointMessage),
      configJSON.AllAssessmentAPIEndPoint
    );
    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestHeaderMessage),
      JSON.stringify(header)
    );
    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestMethodMessage),
      configJSON.httpGetType
    );
    runEngine.sendMessage(requestMessage.id, requestMessage);
  }

  getApiSubjectiveAssessementResult = () => {
    this.setState({isLoading:true})
    const header = {
      "Content-Type": configJSON.categoryApiContentType,
      token: this.state.authToken
    };
    const requestMessage = new Message(
      getName(MessageEnum.RestAPIRequestMessage)
    );
    this.getApiResultSubjectiveAssessementApiCallId = requestMessage.messageId;
    requestMessage.addData(
      getName(MessageEnum.RestAPIResponceEndPointMessage),
      configJSON.AllSubjectiveAssessmentAPIEndPoint
    );
    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestHeaderMessage),
      JSON.stringify(header)
    );
    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestMethodMessage),
      configJSON.httpGetType
    );
    runEngine.sendMessage(requestMessage.id, requestMessage);
  }

  getApiSubjectiveQuestinsResult = () => {
    let assessmentId = this.props.navigation?.history?.location?.state?.assessment_Id;
    let studentId = this.props.navigation?.history?.location?.state?.student_Id;
    this.setState({isLoading:true})
    const header = {
      "Content-Type": configJSON.categoryApiContentType,
      token: this.state.authToken
    };
    const requestMessage = new Message(
      getName(MessageEnum.RestAPIRequestMessage)
    );
    requestMessage.addData(
      getName(MessageEnum.RestAPIResponceEndPointMessage),
      `bx_block_assessmenttest/assessments/subjective_questions_needing_assessment?assessment_id=${assessmentId}&student_id=${studentId}`
    );
    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestHeaderMessage),
      JSON.stringify(header)
    );
    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestMethodMessage),
      configJSON.httpGetType
    );
    this.getApiResultSubjectiveQuestionsApiCallId = requestMessage.messageId;
    runEngine.sendMessage(requestMessage.id, requestMessage);
  }

  serchAssementList = (event:any) => {
    this.setState({serchAssementValue: event.target.value})
  }

  redirectCreateAssessment = () => {
    this.props.navigation.navigate("AssessmenttestCreate")
  }
  onChangeMarks = (event:any, index:any, maxMarks:number) => {
    const { name, value } = event.target;
    let sanitizedValue = value;

    if (sanitizedValue > maxMarks) {
        sanitizedValue = maxMarks;
    } else if (sanitizedValue < 0 || sanitizedValue === "") {
        sanitizedValue = ""; 
    }

    const updatedAssessmentSubjective = [...this.state.assessmentSubjective];
    updatedAssessmentSubjective[index] = {
        ...updatedAssessmentSubjective[index],
        [name]: sanitizedValue,
    };

    this.setState({ assessmentSubjective: updatedAssessmentSubjective });

    const error = this.validateField(name, sanitizedValue, maxMarks);

    const errors = { ...this.state.errors };
    if (error) {
        errors[`question_${index}_${name}`] = error;
    } else {
        delete errors[`question_${index}_${name}`];
    }

    this.setState({ errors });

    if (!error) {
        this.updateStudentAssessmentMarks(index, sanitizedValue);
    }
};
validateField = (name:string, value:any, maxMarks:number) => {
  if (name === "marks") {
      if (value === "" || isNaN(value)) {
          return "Marks are required.";
      }
      // if (Number(value) > maxMarks) {
      //     return `Marks cannot exceed ${maxMarks}.`;
      // }
      if (Number(value) < 0) {
          return "Marks cannot be negative.";
      }
  }
  return "";
};
updateStudentAssessmentMarks = (index:any, value:any) => {
  const updatedStudentAssessments = [...(this.state.studentSubjectiveMarks?.student_assessments || [])];
  const studentAssessmentId = this.state.assessmentSubjectiveQuestions[index].student_assessment_id;

  const markIndex = updatedStudentAssessments.findIndex(item => item.id === studentAssessmentId);
  if (markIndex > -1) {
      updatedStudentAssessments[markIndex].marks = Number(value);
  } else {
      updatedStudentAssessments.push({ id: studentAssessmentId, marks: Number(value) });
  }

  this.setState({
      studentSubjectiveMarks: {
          student_assessments: updatedStudentAssessments,
      },
  });
};

searchResult = (e:any) => {
  this.setState({searchValue:e.target.value, currentPage:1 });
}

  onSubmitSubjectiveAssessment = () => {
    let newErrors:any = {};
    this.state.assessmentSubjective.forEach((question:any, index:number) => {
      Object.keys(question).forEach((keys) => {
        const error = this.validateField(keys, question[keys], index);
        if (error) {
          newErrors[`question_${index}_${keys}`] = error;
        }
      });
    });

    this.setState({errors:newErrors});
    this.apiCallForSubmitMarks();
    // submit API call
    if (Object.keys(newErrors).length === 0) {
      this.setState({errors:{}});
    }
  }

  apiCallForSubmitMarks = async () => {
    const header = {
      "Content-Type": "application/json", 
      token: this.state.authToken
    };
    
    const requestMessage = new Message(
      getName(MessageEnum.RestAPIRequestMessage)
    );
    this.studentSubjectiveMarksApiCallId = requestMessage.messageId;
    let Body = this.state.studentSubjectiveMarks;
    
    requestMessage.addData(
      getName(MessageEnum.RestAPIResponceEndPointMessage),
      "bx_block_assessmenttest/student_scores/update_multiple_subjective_marks"
    );

    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestHeaderMessage),
      JSON.stringify(header)
    );

    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestBodyMessage),
      JSON.stringify(Body)
    );

    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestMethodMessage),
      "POST"
    );
    runEngine.sendMessage(requestMessage.id, requestMessage);
  };

  onChangeFeedback = (event: any, id: any, status: any) => {
    const feedback = event.target.value;
    this.setState((prevState: any) => ({
      feedbackInput: {
          ...prevState.feedbackInput,
          [id]: feedback,
      },
  }));
    const updatedScores  :any= [...this.state.student_scores];
    const index = updatedScores.findIndex((score:any) => score.id === id);

    if (index !== -1) {
        updatedScores[index] = { ...updatedScores[index], feedback, status };
    } else {
        updatedScores.push({ id, feedback, status });
    }
    this.setState({
            student_scores: updatedScores,
    });
};

  apiCallForFeedBack = async () => {
    const header = {
      "Content-Type": "application/json", 
      token: this.state.authToken
    };
    
    const requestMessage = new Message(
      getName(MessageEnum.RestAPIRequestMessage)
    );
    this.studentSubjectiveFeedbackApiCallId = requestMessage.messageId;
    let Body = {
      "student_scores": this.state.student_scores
    }
    
    requestMessage.addData(
      getName(MessageEnum.RestAPIResponceEndPointMessage),
      "bx_block_assessmenttest/student_scores/update_feedback_and_status"
    );

    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestHeaderMessage),
      JSON.stringify(header)
    );

    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestBodyMessage),
      JSON.stringify(Body)
    );

    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestMethodMessage),
      "POST"
    );
    runEngine.sendMessage(requestMessage.id, requestMessage);
  };

  resulListtarray = () => {
    if (this.state.serchAssementValue.length) {
      return this.state.assessmentResultList.filter((xdata: any) => (xdata.attributes.course.course_name).toLowerCase().includes(this.state.serchAssementValue.toLowerCase()))
    } else {
      return this.state.assessmentResultList
    }
  }

  onclickNavigateEdit = (idss:unknown) => {
    this.props.navigation.navigate("AssessmenttestEdit",{id:idss})
  }

  onclickNavigateMarks = (assessmentId:any, studentId:any) => {
    this.props.navigation.navigate('AssessmenttestMarks',{assessment_Id:assessmentId, student_Id:studentId})
}

  selectHistoryDeleteLectureAssesment = (index:number) => {
    let deleteData = this.state.searchHistoryData.filter((object:any, indx:number) => indx !== index)
    this.setState({searchHistoryData:deleteData})
    localStorage.setItem("searchAssesList",JSON.stringify(deleteData))
  }

  onClickSearchModalOpenLectureAssesment = () => {
    this.setState({searchHistory:true})
    let prev = localStorage.getItem('p')
    let now = localStorage.getItem('user_id')
    if(now == prev){
      let data = localStorage.getItem('searchAssesList')
      if(data)
      this.setState({searchHistoryData: JSON.parse(data)})
    }else{
      localStorage.removeItem('searchMyCourses')
      localStorage.removeItem('searchLiveSess')
      localStorage.removeItem('searchAssesList')
    }
    //this.onSearchLogic()
  }


  handleOutsideClickLectureAssesment = (event:any) => {
    (this.state.divRef.current && !this.state.divRef.current.contains(event.target)) && this.onClickSearchModalCloseLectureAssesment()
  };

  onClickSearchModalCloseLectureAssesment = () => {
    const { serchAssementValue, searchHistoryData } = this.state;
    if (serchAssementValue.length !== 0 && !searchHistoryData.includes(serchAssementValue)) {
      let searchData = [serchAssementValue, ...searchHistoryData]
      localStorage.setItem("searchAssesList", JSON.stringify(searchData));
      this.setState({
        searchHistory: false, 
        searchHistoryData: searchData,
      });
    } else {
      this.setState({
        searchHistory: false,
      });
    }
    let prev = localStorage.getItem('p')
    let now = localStorage.getItem('user_id')
    if(now !== prev){
      let now = localStorage.getItem('user_id')
      if(now){
        localStorage.setItem('p',now)
      }
        localStorage.removeItem('searchMyCourses')
        localStorage.removeItem('searchLiveSess')
        localStorage.removeItem('searchAssesList')
    }
  
  }

  onClickSearchValueLectureAssesment = (value:string) => {
    this.setState({serchAssementValue:value,searchHistory:false})
  }

  clearAllHistoryLectureAssesment = () => {
    localStorage.removeItem('searchAssesList')
    this.setState({searchHistoryData:[],serchAssementValue:""})
  }

  handlePageChange = (newPage: number) => {
    this.setState({ currentPage: newPage });
  };

  // Customizable Area End
}
