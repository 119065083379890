import React from "react";
import { Link } from "react-router-dom";
import { MainLogo, image1, image2 } from './assets'
import "../assets/css/EmailAccountLoginMain.web.css";
import EmailAccountLoginController, { Props } from "./EmailAccountLoginController";
import i18n from "../../../components/src/TranslateLanguage";
export default class EmailAccountRegistrationMain extends EmailAccountLoginController {
    constructor(props: Props) {
      super(props);
      // Customizable Area Start
      // Customizable Area End
    }
  render() {
    return (
      <>
        <div className="main-wrapper">
          <div className={`${this.state.currentInterfaceLanguage === "en" ? "signin-form-wrapper" :"signin-form-wrapper ar-row-reverse"}`}>
            <div className="col-6">
              <div className="form-image">
                <div className="login-image">
                  <img src={MainLogo} alt="logo" width={300} />
                </div>
              </div>
            </div>
            <div className="col-6 signup-item" dir={i18n.locale === 'ar' ? 'rtl' : 'ltr'}>
            <div className="close-btn">
                  <span data-test-id="landing-page-go-back" onClick={() => this.props.navigation.navigate('LandingPage')}>
                    <svg xmlns="http://www.w3.org/2000/svg" height="1em" viewBox="0 0 384 512">
                      <path d="M342.6 150.6c12.5-12.5 12.5-32.8 0-45.3s-32.8-12.5-45.3 0L192 210.7 86.6 105.4c-12.5-12.5-32.8-12.5-45.3 0s-12.5 32.8 0 45.3L146.7 256 41.4 361.4c-12.5 12.5-12.5 32.8 0 45.3s32.8 12.5 45.3 0L192 301.3 297.4 406.6c12.5 12.5 32.8 12.5 45.3 0s12.5-32.8 0-45.3L237.3 256 342.6 150.6z" />
                    </svg>
                  </span>
                </div>
              <div className="login-form-wrapper">
                <div className="block-heading">
                  <h4>{i18n.t('SELECT_ROLE')}</h4>
                  {/* <p>{i18n.t('SELECT_ROLE_AS_PER_YOUR_PROFILE')}</p> */}
                </div>
                <div className="block-content">
                  <div className="d-flex mb-4 student-role">
                    <div data-test-id="student-role" className="col-6" onClick={() => this.setState({ selectedRole: "Student"})}>
                      <div className={this.state.selectedRole === "Student" ? "role-wrapper active" : "role-wrapper"}>
                        <div className="role-image">
                          <img className="role-select" src={image1} alt="role-image" />
                        </div>
                        <div className="role-content">        
                          {/* <p>{this.state.currentInterfaceLanguage == "ar" ? "الدخول ك" : "As"}</p> */}
                          <p><strong>{i18n.t('STUDENT')}</strong></p>
                        </div>
                      </div>
                    </div>
                    <div data-test-id="lecturer-role" className="col-6" onClick={() => this.setState({ selectedRole: "Lecturer"})}>
                      <div className={this.state.selectedRole === "Lecturer" ? "role-wrapper active" : "role-wrapper"}>
                        <div className="role-image">
                          <img className="role" src={image2} alt="role-image" />
                        </div>
                        <div className="role-content">
                          {/* <p>{i18n.t('AS')}</p> */}
                          {/* <p>{this.state.currentInterfaceLanguage == "ar" ? "الدخول ك" : "As"}</p> */}
                          <p><strong>{i18n.t('LECTURER')}</strong></p>
                        </div>
                      </div>
                    </div>
                  </div>
                    <input type="button" data-test-id="selectRoleRedirect" onClick={() => this.onClickRedirect()} className="btn button" defaultValue={i18n.t('START_NOW')} />
                </div>
              </div>
            </div>
          </div>
        </div>


      </>
    );
  }
}
