import React from "react";

// Customizable Area Start
import { Box, Typography, Grid, withStyles, ThemeProvider, Paper, createTheme, MenuItem, Select, TableContainer, Table, TableHead, TableRow, TableCell, TableBody, TextField, styled, Menu, Button, ListItemIcon, ListItemText, Checkbox } from "@material-ui/core";
import CircularProgress from '@material-ui/core/CircularProgress';
import NavigationMenu from "../../dashboard/src/NavigationMenu.web";

// Customizable Area End

import AssessmenttestResultController, {
    Props
} from "./AssessmenttestResultController";
import { IconCheck, IconUnCheck } from "./Assessmenttest.web";
import {
    Arrows
  } from "./assets";
import i18nJs from "../../../components/src/TranslateLanguage";

const theme = createTheme({
    palette: {
        primary: {
            main: "#0000ff",
            contrastText: "#fff"
        }
    }
});

export class AssessmenttestResult extends AssessmenttestResultController {
    constructor(props: Props) {
        super(props);
        // Customizable Area Start
        // Customizable Area End
    }

    // Customizable Area Start
    // Customizable Area End

    render() {
        // Customizable Area Start
        let  _sortedData : any = [];
        return (
            <ThemeProvider theme={theme}>
                <NavigationMenu
                    id={this.props.id}
                    navigation={this.props.navigation}
                    title="Assessment test results"
                >
                    <PaperStyle elevation={0} >
                        <Box component="div" className="maindiv">
                            <Grid container style={{ display: "block" }} justifyContent="space-between">
                                <Box component={"div"} className="setSearchbar">
                                    <Box component={"div"} className="setSearchbox">
                                        <Box component={"div"} className="home-search-block">
                                            <Box component={"form"}>
                                                <Box component={"input"} placeholder={i18nJs.t("Search here")} data-test-id="search-input" onChange={(e)=>this.searchResult(e)}/>
                                            </Box>
                                        </Box>
                                    </Box>
                                    <Box component={"div"}>
                                        <Button
                                            aria-controls="customized-menu"
                                            aria-haspopup="true"
                                            variant="contained"
                                            color="primary"
                                            className="paymentFilter"
                                            onClick={this.handleClick}
                                            data-test-id="assessment-result-filter"
                                        >
                                            {i18nJs.t('Sort by')} <img src={Arrows} alt="Arrows logo"  style={{height:"5px", marginLeft:"10px"}}/>
                                        </Button>
                                           
                                        <StyledMenu
                                            data-test-id="assessment-result-filter-close"
                                            id="customized-menu"
                                            anchorEl={this.state.anchorEl}
                                            keepMounted
                                            open={Boolean(this.state.anchorEl)}
                                            onClose={this.handleClose}
                                            getContentAnchorEl={null}
                                            anchorOrigin={{
                                                vertical: 'bottom',
                                                horizontal: 'center',
                                            }}
                                            transformOrigin={{
                                                vertical: 'top',
                                                horizontal: 'center',
                                            }}
                                        >
                                            <StyledMenuItem style={{display: "none"}}>
                                                <ListItemIcon>
                                                <Checkbox data-test-id="checkedId" className="p-0" color="default"  checked={this.state.checked=="sortBy"} icon={<IconUnCheck/>} checkedIcon={<IconCheck/>} value="Old" name="Old" />
                                                </ListItemIcon>
                                                <ListItemText secondary={"Sort By"} />
                                            </StyledMenuItem>
                                            <StyledMenuItem>
                                                <ListItemIcon>
                                                <Checkbox data-test-id="checkedId" onChange={() => this.handleChange("oldest")}  className="p-0" color="default"  checked={this.state.checked=="oldest"} icon={<IconUnCheck/>} checkedIcon={<IconCheck/>} value="Old" name="Old" />
                                                </ListItemIcon>
                                                <ListItemText secondary={i18nJs.t("Oldest to Newest")} />
                                            </StyledMenuItem>
                                            <StyledMenuItem>
                                                <ListItemIcon>
                                                <Checkbox data-test-id="checkedId1" onChange={() => this.handleChange("newest")} className="p-0" color="default" checked={this.state.checked=="newest"} icon={<IconUnCheck/>} checkedIcon={<IconCheck/>} value="New" name="New" />
                                                </ListItemIcon>
                                                <ListItemText primary={i18nJs.t("Newest to Oldest")} />
                                            </StyledMenuItem>
                                        </StyledMenu>
                                       
                                    </Box>
                                </Box>
                            </Grid>
                            <Box data-test-id="tid1" className="paymentTableContentWrapper">
                                <TableContainer id="tid2">
                                    <Table
                                        className="paymentTableContent"
                                        aria-label="simple table"
                                    >
                                        <TableHead className="tableHead">
                                            <TableRow>
                                                <TableCell >{i18nJs.t('S No')}</TableCell>
                                                <TableCell >{i18nJs.t('Student')}</TableCell>
                                                <TableCell >{i18nJs.t('Test')}</TableCell>
                                                <TableCell >{i18nJs.t('Test Date')}</TableCell>
                                                <TableCell >{i18nJs.t('Range')}</TableCell>
                                                <TableCell  className="table-row-style">{i18nJs.t('Grade')}</TableCell>
                                                <TableCell align="center">{i18nJs.t('Pass/Fail')}</TableCell>
                                                <TableCell align="left">{i18nJs.t('Feedback')}</TableCell>
                                            </TableRow>
                                        </TableHead>
                                        <TableBody className="hello table_body">
                                               {(() => {
                                                    const searchValue = this.state.searchValue.trim().toLowerCase();
                                             
                                                    const filteredData = this.state.assessmentSubjectiveList.flatMap((item: any) => {
                                                    const matchedDetails = item.student_details.filter((studentDetail: any) =>
                                                        studentDetail.student.student_name.toLowerCase().includes(searchValue) ||
                                                        item.assessment.name.toLowerCase().includes(searchValue)
                                                    );
                                                
                                                    return matchedDetails.length > 0
                                                        ? [{ ...item, student_details: matchedDetails }]
                                                        : [];
                                                    });                                                                                               

                                                    filteredData.map((item:any)=>{
                                                        item.student_details.map((_item:any)=>{
                                                            _sortedData.push({..._item,assessment:item.assessment,message:_item.message});
                                                        })
                                                    });

                                                    // sorting logic
                                                    const filterSortedData = _sortedData.sort((a: any, b: any) => {
                                                        const dateA = a.submission_date ? new Date(a.submission_date).getTime() : 0;
                                                        const dateB = b.submission_date ? new Date(b.submission_date).getTime() : 0;
                                                        return dateB - dateA;
                                                    });
                                                    
                                                    // sorting filter Newest / Oldest
                                                    const sortedData =  this.state.checked=="oldest" ? _sortedData.sort((a: any, b: any) => {
                                                        const dateA = a.submission_date ? new Date(a.submission_date).getTime() : 0;
                                                        const dateB = b.submission_date ? new Date(b.submission_date).getTime() : 0;
                                                        return dateA - dateB;
                                                    }) : _sortedData.sort((a: any, b: any) => {
                                                        const dateA = a.submission_date ? new Date(a.submission_date).getTime() : 0;
                                                        const dateB = b.submission_date ? new Date(b.submission_date).getTime() : 0;
                                                        return dateB - dateA;
                                                    })
                                                    // Pagination logic
                                                    const start = (this.state.currentPage - 1) * this.state.rowsPerPage;
                                                    const paginationData =filterSortedData.slice(start, start + this.state.rowsPerPage);

                                                 if (searchValue && sortedData.length === 0) {
                                                   return (
                                                     <StyledTableRow>
                                                       <TableCell colSpan={8} align="center">
                                                         No results found for "{this.state.searchValue}".
                                                       </TableCell>
                                                     </StyledTableRow>
                                                   );
                                                 }

                                                   return  paginationData.map((studentDetail: any, globalIndex:number) => {                                             
                                                     return (
                                                       <StyledTableRow key={`${((this.state.currentPage - 1) * 6) + (globalIndex+1)}`} data-test-id="tid3">
                                                         <TableCell id="tid4">{((this.state.currentPage - 1) * 6) + (globalIndex+1)}</TableCell>
                                                         <TableCell  data-test-id="tid5">{studentDetail.student.student_name}</TableCell>
                                                         <TableCell  id="tid6">
                                                           {studentDetail.message === "Subjective questions need to be assessed" ? (
                                                             <Box
                                                               component="span"
                                                               className="courses-name-table"
                                                               data-test-id="courses-name-table"
                                                               onClick={() => this.onclickNavigateMarks(studentDetail.assessment.assessment_id, studentDetail.student.account_id)}
                                                             >
                                                               {studentDetail.assessment.name}
                                                             </Box>
                                                           ) : (
                                                             <Box component="span" data-test-id="courses-name-table">
                                                                 {studentDetail.assessment.name}
                                                             </Box>
                                                            )}
                                                         </TableCell>
                                                         <TableCell align="center" className="tid7">
                                                           {(() => {
                                                             const date = new Date(studentDetail.submission_date);
                                                             const day = String(date.getDate()).padStart(2, '0');
                                                             const month = String(date.getMonth() + 1).padStart(2, '0');
                                                             const year = date.getFullYear();
                                                             return `${day}/${month}/${year}`;
                                                           })()}
                                                         </TableCell>
                                                         <TableCell align="center">0.00 - 100.00</TableCell>
                                                         <TableCell align="center">
                                                           <CssTextField
                                                             style={{ minWidth: '90px', maxWidth: '90px' }}
                                                             id="outlined-size-normal"
                                                             defaultValue={studentDetail.score ? studentDetail.score.toFixed(2) : "0.00"}
                                                             disabled={!!studentDetail.score}
                                                             variant="outlined"
                                                           />
                                                         </TableCell>
                                                         <TableCell align="center">
                                                          {studentDetail.message !== "Subjective questions need to be assessed" ?
                                                           <Select
                                                             className={studentDetail.status === "Pass" ? "paymentFilter1" : "yourSecondClassName"}
                                                             value={studentDetail.status || "Fail"}
                                                             onChange={(e) => this.handleChange1(e)}
                                                             disabled={studentDetail.status === "Pass" || studentDetail.status === "Fail"}
                                                             data-test-id="handleChangeId"
                                                             disableUnderline
                                                             MenuProps={{
                                                               anchorOrigin: {
                                                                 vertical: 'bottom',
                                                                 horizontal: 'left',
                                                               },
                                                               transformOrigin: {
                                                                 vertical: 'top',
                                                                 horizontal: 'left',
                                                               },
                                                               getContentAnchorEl: null,
                                                             }}
                                                           >
                                                             <MenuItem value="Pass">{i18nJs.t('pass')}</MenuItem>
                                                             <MenuItem value="Fail">{i18nJs.t('fail')}</MenuItem>
                                                           </Select>:
                                                            <Box
                                                                style={{ 
                                                                    background: "#E2E8F0",
                                                                    height: "48px",                                                                    
                                                                    display: "inline-flex",
                                                                    padding: "0px 10px",
                                                                    textAlign: "center",
                                                                    alignItems: "center",
                                                                    borderRadius: "4px",
                                                                    justifyContent: "center",
                                                                 }}
                                                            >{i18nJs.t("pass_fail")}</Box>}
                                                         </TableCell>
                                                         <TableCell align="left">
                                                           <CssTextField1
                                                             style={{ minWidth: '219px', maxWidth: '281px' }}
                                                             fullWidth
                                                             data-test-id="ChangeFeedbackTest"
                                                             id="outlined-size-normal"
                                                             placeholder={i18nJs.t("TYPE_HERE")}
                                                             variant="outlined"
                                                             value={this.state.feedbackInput?.[studentDetail.student_score_id] || ""}
                                                             onChange={(event) => this.onChangeFeedback(event, studentDetail.student_score_id, studentDetail.status)}
                                                           />
                                                         </TableCell>
                                                       </StyledTableRow>
                                                     );
                                                   });
                                               })()}
                                             </TableBody>

                                    </Table>
                                </TableContainer>
                            </Box>
                            <Box id="idno1" className="paymentNavBlock" dir="ltr">
                                
                                <Button 
                                    disabled={this.state.currentPage===1}
                                    data-test-id="checkid" className="paymentNavPrev"
                                    onClick={() => this.handlePageChange(Number(this.state.currentPage - 1))}
                                >
                                    <svg
                                        viewBox="0 0 24 24"
                                        height="24"
                                        xmlns="http://www.w3.org/2000/svg"
                                        fill="none"
                                        width="24"
                                    >
                                        <path
                                            fill="#64748B"
                                            d="M15 6.70459C14.8132 6.51734 14.5595 6.41211 14.295 6.41211C14.0305 6.41211 13.7769 6.51734 13.59 6.70459L9.00002 11.2946C8.61002 11.6846 8.61002 12.3146 9.00002 12.7046L13.59 17.2946C13.98 17.6846 14.61 17.6846 15 17.2946C15.39 16.9046 15.39 16.2746 15 15.8846L11.12 11.9946L15 8.11459C15.39 7.72459 15.38 7.08459 15 6.70459Z"
                                        />
                                    </svg>
                                </Button>
                                <Button 
                                    disabled={this.state.currentPage*6 > _sortedData.length}
                                    data-test-id="typoid" className="paymentNavNext"
                                    onClick={() => this.handlePageChange(Number(this.state.currentPage + 1))}
                                >
                                    <svg
                                        width="24"
                                        fill="none"
                                        height="24"
                                        viewBox="0 0 24 24"
                                        xmlns="http://www.w3.org/2000/svg"
                                    >
                                        <path
                                            fill="white"
                                            d="M9.00002 6.70978C8.61002 7.09978 8.61002 7.72978 9.00002 8.11978L12.88 11.9998L9.00002 15.8798C8.61002 16.2698 8.61002 16.8998 9.00002 17.2898C9.39002 17.6798 10.02 17.6798 10.41 17.2898L15 12.6998C15.39 12.3098 15.39 11.6798 15 11.2898L10.41 6.69978C10.03 6.31978 9.39002 6.31978 9.00002 6.70978Z"
                                        />
                                    </svg>
                                </Button>
                            </Box>
                            <Box component={"div"} style={{ marginTop: "40px" }} className="modal-footer p-0 profile-button mb-25">
                                <button type="button" data-test-id="go-back-save-course" className="btn button-outline-profile" onClick={() => this.onClickGoBack()}>{i18nJs.t('Cancel')}</button>
                                <button type="button" className="setButtonArea btn button" data-test-id="assessment-test-onSubmitData" disabled={this.state.getAnswer} onClick={() => this.apiCallForFeedBack()}>{this.state.getAnswer ? <CircularProgress size={30} className='text-white' /> : i18nJs.t("Submit Result")}</button>
                            </Box>
                        </Box>
                    </PaperStyle>
                </NavigationMenu>
            </ThemeProvider>
        )
        // Customizable Area End
    }
}

// Customizable Area Start

const PaperStyle = styled(Paper)({
    width: "100%", overflow: "hidden",
    "& .table-row-style":{minWidth: '90px', maxWidth: '90px'},
    "& .maindiv": {
        padding: '40px',
        background: '#F6F8FA',
        height: 'calc(100vh - 110px)',
        overflowY: 'auto',
        overflow: 'hidden' as const,
        '& .MuiTableHead-root ': {
            '& .MuiTableRow-root ': {
                '& .MuiTableCell-root ': {
                    fontFamily: 'Corbel V2',
                    border: 'none',
                    fontSize: '16px',
                    fontWeight: 'bold',
                }
            }
        },
        '& .MuiTableBody-root': {
            '& .MuiTableRow-root': {
                background: "#fff",
                borderRadius: '10px',

                '& .MuiTableCell-root ': {
                    '& .MuiFormControl-root': {
                        '& .MuiOutlinedInput-root': {
                            display: 'inline-flex',
                            padding: '0px 10px',
                            justifyContent: 'center',
                            alignItems: 'center',
                            textAlign: 'center',
                            height: '48px',
                            borderadius: '16px',
                            background: '#E2E8F0'
                        }
                    }
                }
            }
        },
        '&::-webkit-scrollbar': {
            width: '5px',
        },
        '&::-webkit-scrollbar-track': {
            borderRadius: '10px',
            backgroundColor: 'transparent',
        },
        '&::-webkit-scrollbar-thumb': {
            borderRadius: '10px',
            background: 'rgb(32, 94, 131, 0.5)',
        },
        '@media (max-width: 1280px)': {
            overflow: 'auto' as const,
        },
        '@media (max-width: 575px)': {
            height: 'calc(100vh - 150px)',
        },
    },
    "& .setSearchbar": {
        width: "100%",
        display: 'flex',
        justifyContent: 'space-between',
        alignItems: 'flex-start',
        gap: "20px"
    },
    "& .setSearchbox": {
        width: "90%",
    },
    "& .paymentFilter": {
        width: 115,
        padding: "14px 10px",
        borderRadius: '8px',
        color: '#FFF',
        fontFamily: 'Corbel V2',
        fontSize: '16px',
        fontStyle: 'normal',
        fontWeight: 'bold',
        lineHeight: '24px',
        textTransform: 'capitalize',
        background: '#37657F',
        '&:hover': {
            background: '#37657F'
        },
        "& .MuiSelect-selectMenu": {
            borderRadius: 8,
            background: "#37657F",
            padding: "14px 26px",
            color: "#fff",
            fontFamily: "Corbel V2",
            fontSize: 16,
            fontStyle: "normal",
            fontWeight: 700,
            lineHeight: "24px",
        },
        "& .MuiSelect-icon": {
            color: "#fff",
        },
    },
    "& .paymentTableContentWrapper": {
        '& .MuiTableContainer-root': {
            '&::-webkit-scrollbar': {
                width: '3px',
                height: '5px'
            },
            '&::-webkit-scrollbar-track': {
                backgroundColor: 'transparent',
                borderRadius: '10px',
            },
            '&::-webkit-scrollbar-thumb': {
                background: 'rgb(32, 94, 131, 0.5)',
                borderRadius: '10px',
            },
        },
    },
    "& .paymentTableContent": {
        borderCollapse: "separate",
        borderSpacing: "0 10px",
        "& .MuiTableCell-root": {
            color: "#334155",
            fontFamily: "Corbel V2",
            fontSize: 14,
            fontStyle: "normal",
            fontWeight: 400,
            lineHeight: "normal",
            wordBreak: 'break-all',
            whiteSpace: 'nowrap',
        },
        "& svg": {
            color: "#334155",
            fontFamily: "Corbel V2",
            fontSize: 14,
            fontStyle: "normal",
            fontWeight: 400,
            lineHeight: "normal",
            wordBreak: 'break-all',
            whiteSpace: 'nowrap',
            verticalAlign: "middle",
        },
    },
    "& .tableHead": {
        color: '#000',
        fontFamily: 'Corbel V2',
        fontSize: '16px',
        fontStyle: 'normal',
        fontWeight: 'bold',
        lineHeight: 'normal',
        wordBreak: 'break-all',
        whiteSpace: 'nowrap',
    },
    "& .paymentFilter1": {
        width: 70,
        '@media (max-width: 767px)': {
            width: '100%',
        },
        "& .MuiSelect-selectMenu": {
            borderRadius: 8,
            background: "#37657F",
            padding: "9px 21px",
            color: "#fff",
            fontFamily: "Corbel V2",
            fontSize: 14,
            fontStyle: "normal",
            fontWeight: 700,
            lineHeight: "24px",
        },
        "& .MuiSelect-icon": {
            color: "#fff",
            top: '12px',
            right: '8px',
            display: "none"
        },
    },
    "& .yourSecondClassName": {
        width: 70,
        '@media (max-width: 767px)': {
            width: '100%',
        },
        "& .MuiSelect-selectMenu": {
            borderRadius: 8,
            background: "#DC2626",
            padding: "9px 14px",
            color: "#fff",
            fontFamily: "Corbel V2",
            fontSize: 14,
            fontStyle: "normal",
            fontWeight: 700,
            lineHeight: "24px",
        },
        "& .MuiSelect-icon": {
            color: "#fff",
            top: '12px',
            right: '8px',
            display: "none"
        },
    },
    "& .paymentNavBlock": {
        display: "flex",
        alignItems: "center",
        justifyContent: "flex-end",
        gap: 16,
        marginTop: 40,
    },
    "& .paymentNavPrev": {
        background: "#CBD5E1",
        borderRadius: 8,
        display: "inline-block",
        width: 44,
        height: 44,
        padding: 10,
        cursor: "pointer",
    },
    "& .paymentNavNext": {
        background: "#37657F",
        color: "#fff",
        borderRadius: 8,
        display: "inline-block",
        width: 44,
        height: 44,
        padding: 10,
        cursor: "pointer",
    },
    "& .setButtonArea": {
        '@media (max-width: 575px)': {
            maxWidth: '100% !important'
        },
    },
    "& .courses-name-table":{
        borderBottom:"1px solid #334155"
    }
  })  

const CssTextField = withStyles({
    root: {
        '& label.Mui-focused': {
            color: 'green',
        },
        '& .MuiInput-underline:after': {
            borderBottomColor: 'green',
        },
        '& .MuiOutlinedInput-root': {
            '& fieldset': {
                borderColor: 'transparent',
            },
            '&:hover fieldset': {
                borderColor: 'transparent',
            },
            '&.Mui-focused fieldset': {
                borderColor: 'transparent',
            },
        },
    },
})(TextField);

const CssTextField1 = withStyles({
    root: {
        '& label.Mui-focused': {
            color: 'green',
        },
        '& .MuiInput-underline:after': {
            borderBottomColor: 'green',
        },
        '& .MuiOutlinedInput-root': {
            '& fieldset': {
                borderColor: 'transparent',
            },
            '&:hover fieldset': {
                borderColor: 'transparent',
            },
            '&.Mui-focused fieldset': {
                borderColor: 'transparent',
            },
        },
    },
})(TextField);

const StyledTableRow = styled(TableRow)({
    cursor:"pointer",
    "& .MuiTableCell-root:last-child": {
        // border: "20px solid red",
        borderRadius: "0 50px 50px 0",
    },
    "& .MuiTableCell-root:first-child": {
        // border: "20px solid red",
        borderRadius: "10px 0 0 10px",
    },
    "& .MuiTableCell-root": {
        border: "none",
    }
});

const StyledMenu = styled(Menu)({
    "& .MuiList-root": {
        "& .MuiButtonBase-root": {
          "& .MuiListItemIcon-root": {
            minWidth: '30px',
            maxWidth: '30px',
          },
          "& .MuiListItemText-root": {
            "& .MuiTypography-root": {
              color: '#37657F',
              fontFamily: 'Corbel V2',
              fontSize: '16px',
              fontStyle: 'normal',
              fontWeight: 400,
              lineHeight: 'normal',
            },
          },
        },
      },
      border: '1px solid #d3d4d5',
})

const StyledMenuItem = styled(MenuItem)({
    '&:focus': {
        backgroundColor: "#fff",
        '& .MuiListItemIcon-root, & .MuiListItemText-primary, & .MuiListItemText-secondary': {
            color: 'black'
        },
    },
})

export default AssessmenttestResult
// Customizable Area End
