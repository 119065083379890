import { Dimensions, Platform } from "react-native";

//Screen Constatnts
const SCREEN_HEIGHT = 736;
const SCREEN_WIDTH = 414;

const { height, width } = Dimensions.get("window");

/**
 * Function to scale a value based on the size of the screen size and the original
 * size used on the design.
 */
export default function(units = 1) {
  return (width / SCREEN_WIDTH) * units;
}

const verticalScale = (size: number) => (height / SCREEN_HEIGHT) * size;

export { verticalScale };

export const TernaryCheck = (condition:any, value1:any, value2:any) => {
  return condition ? value1 : value2
}
