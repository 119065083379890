import React, { Component } from "react";

class PreventBackNavigation extends Component {
  componentDidMount() {
    this.preventBack();
    window.addEventListener("popstate", this.preventBack);
  }

  componentWillUnmount() {
    window.removeEventListener("popstate", this.preventBack);
  }

  preventBack = () => {
    console.log("Preventing back navigation"); // Debugging
    window.history.pushState(null, "", window.location.href);
    setTimeout(() => {
      window.history.pushState(null, "", window.location.href); // Double push to ensure prevention
    }, 0);
  };

  render() {
    return null;
  }
}

export default PreventBackNavigation;
