import { IBlock } from "../../../framework/src/IBlock";
import { Message } from "../../../framework/src/Message";
import { BlockComponent } from "../../../framework/src/BlockComponent";
import MessageEnum, {
  getName,
} from "../../../framework/src/Messages/MessageEnum";
import { runEngine } from "../../../framework/src/RunEngine";
import { getStorageData, removeStorageData, setStorageData } from "../../../framework/src/Utilities";

// Customizable Area Start
// Customizable Area End

export const configJSON = require("./config");

// Customizable Area Start
export interface ITermsConds {
  is_accepted: boolean;
  id: string;
  description: string;
  created_at: string;
}

// Customizable Area End
export interface Props {
  navigation: any;
  id: string;
  // Customizable Area Start
  classes:any;
  location?:any
  // Customizable Area End
}

interface S {
  // Customizable Area Start
  token: string;
  isAdminUser: boolean;
  isLoading: boolean;
  accountId: number;
  termsCondsList: ITermsConds[];
  isTermsCondsAccepted: boolean;
  termsConds: ITermsConds | null;
  termsCondsData: any;
  value:any;
  fileName:any;
  getDisbled:boolean;
  identificationcard:any;
  personalphoto:any;
  resume:any;
  toggleTabpan: string;
  uploaded_Payment_documents:any;
  flag:boolean;
  signupData:any;
  // Customizable Area End
}

interface SS {
  id: string;
  // Customizable Area Start
  // Customizable Area End
}

export default class PrivacyPolicyController extends BlockComponent<
  Props,
  S,
  SS
> {
  // Customizable Area Start
  setAcceptanceOfTermsCondsId: string = "";
  getTermsCondsListCallId: string = "";
  getAccountGroupsCallId: string = "";
  getTermsCondsCallId: string = "";
  getTermsConditionDataCallId : string = "";
  signupApiCallId1: string = "";
  signuplecutureApiCallId1: string = ""
  // Customizable Area End

  constructor(props: Props) {
    super(props);
    this.receive = this.receive.bind(this);

    this.subScribedMessages = [
      // Customizable Area Start
      getName(MessageEnum.RestAPIResponceMessage),
      getName(MessageEnum.NavigationPayLoadMessage),
      getName(MessageEnum.SessionResponseMessage),
      // Customizable Area End
    ];

    this.state = {
      // Customizable Area Start
      token: "",
      isLoading: false,
      termsCondsList: [],
      termsConds: null,
      isAdminUser: true,
      accountId: -1,
      isTermsCondsAccepted: false,
      termsCondsData: [],
      value:{},
      fileName:{},
      getDisbled:false,
      identificationcard:{},
      personalphoto:{},
      resume:{},
      toggleTabpan: "payoffline",
      uploaded_Payment_documents:{},
      flag:false,
      signupData:{}
      // Customizable Area End
    };
    runEngine.attachBuildingBlock(this as IBlock, this.subScribedMessages);

    // Customizable Area Start
    // Customizable Area End
  }

  // Customizable Area Start
  async componentDidMount() {
    super.componentDidMount();
    this.getToken();
    this.getTermsConditionData();
    this.getLocalData()
  }

  getToken = () => {
    const message: Message = new Message(
      getName(MessageEnum.SessionRequestMessage)
    );
    this.send(message);
  };

  getLocalData = async() => {
    this.setState({signupData:this.props?.location?.state?.data})
  }

  getTermsConditionData = async() => {

    this.setState({ flag : true});

    const getProfileIdRequest: Message = new Message(
      getName(MessageEnum.RestAPIRequestMessage)
    );
    this.getTermsConditionDataCallId = getProfileIdRequest.messageId;
    getProfileIdRequest.addData(
      getName(MessageEnum.RestAPIResponceEndPointMessage),
      configJSON.privacyPolicyData
    );
    getProfileIdRequest.addData(
      getName(MessageEnum.RestAPIRequestMethodMessage),
      configJSON.validationApiMethodType
    );

    runEngine.sendMessage(getProfileIdRequest.id, getProfileIdRequest);
  }

  onCallPressButton = async() => {
    const selectRole =  await getStorageData('role')
    const getUserToken =  await getStorageData('userToken')
    const getUserId =  await getStorageData('userId')
    
    let obj = {
      token: getUserToken,
      id: getUserId,
    }
    selectRole === "Student" && this.handleLoginApiResponse(obj)
    selectRole === "Lecturer" && this.handleSecondLoginApiResponse(obj)
  }

   onSignup =  async () => {
    this.setState({getDisbled:true})
    const {signupData} = this.state
    
    setStorageData("email", signupData.email)
     
    const requestMessage = new Message(
      getName(MessageEnum.RestAPIRequestMessage)
    );

    this.signupApiCallId1 = requestMessage.messageId;
    const header = {
      redirect: 'follow'
    };

    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestHeaderMessage),
      JSON.stringify(header)
    );


    const formData = new FormData();
    formData.append("identification_card", signupData?.card)
    formData.append("data[attributes]first_name", signupData?.fname)
    formData.append("data[attributes]middle_name", signupData?.lname)
    formData.append("data[attributes]last_name", signupData?.lname)
    formData.append("data[attributes]email", signupData?.email)
    formData.append("data[attributes]password", signupData?.password)
    formData.append("data[attributes]password_confirmation", signupData?.con_password)
    formData.append("data[attributes]qualification", "BE")
    formData.append("data[attributes]full_phone_number", signupData?.phno)
    formData.append("data[attributes]role_id", "1")
    formData.append("data[attributes]date_of_birth", signupData?.date)
    formData.append("data[attributes]gender", signupData?.gender)
    formData.append("data[attributes]country", signupData?.country)
    formData.append("data[attributes]city", signupData?.city)
    formData.append("data[attributes]occupation", signupData?.occupation)
    formData.append("data[attributes]activated", "true")
    formData.append("data[type]", "email_account")
    
    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestBodyMessage),
      formData
    );

    requestMessage.addData(
      getName(MessageEnum.RestAPIResponceEndPointMessage),
      "account_block/accounts"
    );

    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestMethodMessage),
      "POST"
    );

    runEngine.sendMessage(requestMessage.id, requestMessage);
  }

  onLecutureSignup =  async () => {
    this.setState({getDisbled:true})
    const {signupData} = this.state
    
    setStorageData("email", signupData.officialemail)
    
     
    const requestMessage = new Message(
      getName(MessageEnum.RestAPIRequestMessage)
    );

    this.signuplecutureApiCallId1 = requestMessage.messageId;
    const header = {
      redirect: 'follow'
    };

    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestHeaderMessage),
      JSON.stringify(header)
    );


    const formData = new FormData();
    formData.append("identification_card", signupData?.identificationcard)
    formData.append("profile_picture", signupData?.personalphoto)
    formData.append("resume", signupData?.resume)
    formData.append("data[attributes][first_name]", signupData?.firstname)
    formData.append("data[attributes][middle_name]", signupData?.lastname)
    formData.append("data[attributes][last_name]", signupData?.lastname)
    formData.append("data[attributes][email]", signupData?.officialemail)
    formData.append("data[attributes][password]", signupData?.password)
    formData.append("data[attributes][password_confirmation]", signupData?.confirmpassword)
    formData.append("data[attributes][qualification]", signupData?.qualification)
    formData.append("data[attributes][full_phone_number]", signupData?.phonenumber)
    formData.append("data[attributes][role_id]", "2")
    formData.append("data[attributes][date_of_birth]", signupData?.dateofbirth)
    formData.append("data[attributes][department]", signupData?.department)
    formData.append("data[attributes][major]", signupData?.major)
    formData.append("data[attributes][experience]", signupData?.experience)
    formData.append("data[attributes][biography]", signupData?.biography)
    formData.append("data[attributes][activated]", "true")
    formData.append("data[type]", "email_account")
    
    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestBodyMessage),
      formData
    );

    requestMessage.addData(
      getName(MessageEnum.RestAPIResponceEndPointMessage),
      "account_block/accounts"
    );

    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestMethodMessage),
      "POST"
    );

    runEngine.sendMessage(requestMessage.id, requestMessage);
  }
  
  handleLoginApiResponse = (responseJson:any) => {
      setStorageData("token",responseJson.token)
      setStorageData("user_id", responseJson.id)
      this.setState({getDisbled:false})
      this.props.navigation.navigate("Home");
  };

  handleSecondLoginApiResponse = (responseJson:any) => {
    setStorageData("token",responseJson.token)
    setStorageData("user_id", responseJson.id)
    this.setState({getDisbled:false})
    this.props.navigation.navigate("Home");
};

handleFileChange = (event: any) => {
  const Filename = event?.target?.files[0]; 
  this.setState({ uploaded_Payment_documents: Filename });
};

  // Customizable Area End

  async receive(from: string, message: Message) {
    // Customizable Area Start
    const apiRequestCallId = message.getData(
      getName(MessageEnum.RestAPIResponceDataMessage)
    );
    const responseJson = message.getData(
      getName(MessageEnum.RestAPIResponceSuccessMessage)
    );

    if(this.getTermsConditionDataCallId == apiRequestCallId) {
      if(responseJson && responseJson.data){
        this.setState({ termsCondsData : responseJson.data , flag : false});
      }
    }

    (this.signupApiCallId1 === apiRequestCallId && responseJson.data) && this.handleLoginApiResponse(responseJson);
    (this.signuplecutureApiCallId1 === apiRequestCallId && responseJson.data) && this.handleSecondLoginApiResponse(responseJson);

    
    // Customizable Area End
  }
}
