import React from 'react';
// Customizable Area Start
import {
  Paper,
  LinearProgress,
  CircularProgress,
  Dialog,
  DialogContent,
  DialogTitle,
  Box,
  Typography,
  Grid,
  Button
} from "@material-ui/core";
import { createTheme, styled, ThemeProvider } from "@material-ui/core/styles";
import Alert from "@material-ui/lab/Alert"
import IconButton from '@material-ui/core/IconButton';
import CloseIcon from '@material-ui/icons/Close';
import NavigationMenu from "../../dashboard/src/NavigationMenu.web";
import EditLecturerUserProfileController, { Props } from './EditLecturerUserProfileController';
import { Formik, Field, ErrorMessage } from 'formik';
import * as Yup from 'yup';
import i18nJs from '../../../components/src/TranslateLanguage';
import moment from 'moment';
import DatePicker from "react-datepicker";
import "react-datepicker/dist/react-datepicker.css";

const baseURL = require("../../../framework/src/config.js").baseURL
// Customizable Area End

const theme = createTheme({
  palette: {
    primary: {
      contrastText: "#fff",
      main: "#0000ff",
    },
  },
});

const validationSchema = Yup.object({
  lname: Yup.string().matches(/^[A-Za-z ]*$/, 'Please enter last name.').required('Last name is required.'),
  fname: Yup.string().matches(/^[A-Za-z ]*$/, 'Please enter valid name.').required('First name is required.'),
  phno: Yup.string().matches(/^(\+)[0-9 ]{7,15}$/, 'Please enter valid number.').required('Number is required.'),
  date: Yup.string().nullable().required('DOB is required.'),
  email: Yup.string()
    .email('Invalid email address.')
    .matches(
      /^[a-z0-9._%+-]+@[a-z0-9.-]+\.[a-z]{2,4}$/, // Replace with your desired pattern
      'Please enter valid email address.'
    )
    .required('Email is required.'),
  qualification:Yup.string().matches(/^[A-Za-z ]*$/, "Please enter valid department.").required('Qualification is required.'),
  experience:Yup.string().required('Experience is required.'),
  biography:Yup.string().required('Biography is required.'),
  major:Yup.string().matches(/^[A-Za-z ]*$/, "Please enter valid major").required('Major is required.'),
  department:Yup.string().matches(/^[A-Za-z ]*$/, "Please enter valid department.").required('Department is required.'),
  card:Yup.mixed().required("Identification card is required."),
  resume:Yup.mixed().required("Resume is required."),
});


export default class EditLecturerUserProfile extends EditLecturerUserProfileController {
  constructor(props: Props) {
    // Customizable Area Start
    super(props);
    // Customizable Area End

  }
  // Customizable Area Start
  sucessModalVisible = () => {
    return (
      <Dialog maxWidth={'xs'} fullWidth aria-labelledby="simple-dialog-title" open={this.state.sucessModalVisible}>
        <VisibleStyle>
          <DialogTitle id="simple-dialog-title" className='modalHeader'>
            <Button id='closeModal' data-test-id="close_button_modal" onClick={() => { this.setState({ sucessModalVisible: false }) }} type="button" className="visibleClose">
              <span>×</span>
            </Button></DialogTitle>
          <DialogContent className='dialogContentBody'>
            <Box className="modal-icon">
              <svg xmlns="http://www.w3.org/2000/svg" height="1em" viewBox="0 0 448 512">
                <path d="M304 128a80 80 0 1 0 -160 0 80 80 0 1 0 160 0zM96 128a128 128 0 1 1 256 0A128 128 0 1 1 96 128zM49.3 464H398.7c-8.9-63.3-63.3-112-129-112H178.3c-65.7 0-120.1 48.7-129 112zM0 482.3C0 383.8 79.8 304 178.3 304h91.4C368.2 304 448 383.8 448 482.3c0 16.4-13.3 29.7-29.7 29.7H29.7C13.3 512 0 498.7 0 482.3z" />
              </svg>
            </Box>
            <h5>{i18nJs.t('PROFILE_UPDATED')}</h5>
             <p>{i18nJs.t('The required user profile details were successfully updated')}</p>
           </DialogContent>
        </VisibleStyle>
      </Dialog>
    )
  }

  modalDeleteVisible = () => {
    return (<Dialog maxWidth={'xs'} fullWidth aria-labelledby="simple-dialog-title" open={this.state.modalDeleteVisible}>
      <DeleteModalStyle className="modal-block none-modal">
        <Box className="modalDialog">
          <Box className="modal-content">
            <Box className="modalHeader">
              <Button id='modalDeleteVisible' data-test-id="close_button_modal_modalDeleteVisible" onClick={() => { this.setState({ modalDeleteVisible: false }) }} type="button" className="closeDeleteBtn">
                <span>×</span>
              </Button>
            </Box>
            <Box className="modalBody">
              <Box className="modal-icon">
                <svg xmlns="http://www.w3.org/2000/svg" width="26" height="26" viewBox="0 0 26 26" fill="none"><path fillRule="evenodd" clipRule="evenodd" d="M16.0228 3.56417L16.792 4.33333H19.5003C20.0962 4.33333 20.5837 4.82083 20.5837 5.41667C20.5837 6.0125 20.0962 6.5 19.5003 6.5H6.50033C5.90449 6.5 5.41699 6.0125 5.41699 5.41667C5.41699 4.82083 5.90449 4.33333 6.50033 4.33333H9.20866L9.97783 3.56417C10.1728 3.36917 10.4545 3.25 10.7362 3.25H15.2645C15.5462 3.25 15.8278 3.36917 16.0228 3.56417ZM6.50033 20.5833C6.50033 21.775 7.47533 22.75 8.66699 22.75H17.3337C18.5253 22.75 19.5003 21.775 19.5003 20.5833V9.75C19.5003 8.55833 18.5253 7.58333 17.3337 7.58333H8.66699C7.47533 7.58333 6.50033 8.55833 6.50033 9.75V20.5833ZM9.75033 9.75H16.2503C16.8462 9.75 17.3337 10.2375 17.3337 10.8333V19.5C17.3337 20.0958 16.8462 20.5833 16.2503 20.5833H9.75033C9.15449 20.5833 8.66699 20.0958 8.66699 19.5V10.8333C8.66699 10.2375 9.15449 9.75 9.75033 9.75Z" fill="#37657F" /></svg>
              </Box>
              <h5>{i18nJs.t('Account Delete')}</h5>
              <p>{i18nJs.t('Are you sure want to delete this account')}</p>
            </Box>
            <Box className="modalFooter">
              <Button id='accountDeleteModal' data-test-id="account_delete__modal" onClick={() => { this.setState({ modalDeleteVisible: false }) }} type="button" className="cancelBtn" data-dismiss="modal">{i18nJs.t('Cancel Delete')}</Button>
              <Button id='deleteAccountBtn' type="button" className="deleteBtn" data-test-id="delete-account-button" disabled={this.state.accountDeleteLoading} onClick={() => this.accountDelete()}>{this.state.accountDeleteLoading ? <CircularProgress size={30} className='text-white' /> : i18nJs.t('Delete Account')}</Button>
            </Box>
          </Box>
        </Box>
      </DeleteModalStyle>
    </Dialog>)
  }

  fileUploadModalVisible = () => {
    return (
      <Dialog maxWidth="lg" fullWidth open={this.state.fileUploadModalVisible}>
        <ModalStyle className="uploadModalBlock">
          <Box className={`modalDialog ${i18nJs.locale === 'ar' ? "profile_upload_modal_arabic" : "profile_upload_modal"}`}>
            <Box className="modal-content">
              <Box className="modalHeader">
                <Box className="blockHeading">
                  <h3>{i18nJs.t('Upload Profile Image')}</h3>
                  {/* <p>{i18nJs.t('Upload the profile image from your file manager to show facial recognition.')}</p> */}
                </Box>
                <Button id='closeFileUploadModalVisible' onClick={() => { this.setState({ progressUpload: null,fileUploadModalVisible: false, imagesProfile: {},filesUploadFlag:"", fileData: null }) }} type="button" className="closeBtn">
                  <span>×</span>
                </Button>
              </Box>
              <Box className="modalBody">
                <Box className="dargBlock">
                  <Box className="uploadBtnWrapper"
                      onClick={this.handleClick}
                      >
                    <Button
                      className="dropButton"
                      onDrop={this.handleDrop}
                      onDragOver={this.handleDragOver}
                      onDragEnter={this.handleDragEnter}
                      onDragLeave={this.handleDragLeave}

                    >
                      {this.state.fileData ? (
                        <img src={URL.createObjectURL(this.state.fileData)} />
                      ) : (
                        <>
                          <svg xmlns="http://www.w3.org/2000/svg" width="24" height="24" viewBox="0 0 24 24" fill="none">
                            <path d="M12 15.575C11.7167 15.575 11.4793 15.479 11.288 15.287C11.096 15.0957 11 14.8583 11 14.575V7.425L9.125 9.3C8.925 9.5 8.69167 9.6 8.425 9.6C8.15833 9.6 7.91667 9.49167 7.7 9.275C7.5 9.075 7.40433 8.83733 7.413 8.562C7.421 8.28733 7.51667 8.05833 7.7 7.875L11.3 4.275C11.4 4.175 11.5083 4.104 11.625 4.062C11.7417 4.02067 11.8667 4 12 4C12.1333 4 12.2583 4.02067 12.375 4.062C12.4917 4.104 12.6 4.175 12.7 4.275L16.3 7.875C16.5 8.075 16.5957 8.31233 16.587 8.587C16.579 8.86233 16.4833 9.09167 16.3 9.275C16.1 9.475 15.8627 9.579 15.588 9.587C15.3127 9.59567 15.075 9.5 14.875 9.3L13 7.425V14.575C13 14.8583 12.9043 15.0957 12.713 15.287C12.521 15.479 12.2833 15.575 12 15.575ZM6 19.575C5.45 19.575 4.97933 19.3793 4.588 18.988C4.196 18.596 4 18.125 4 17.575V15.575C4 15.2917 4.09567 15.054 4.287 14.862C4.479 14.6707 4.71667 14.575 5 14.575C5.28333 14.575 5.521 14.6707 5.713 14.862C5.90433 15.054 6 15.2917 6 15.575V17.575H18V15.575C18 15.2917 18.096 15.054 18.288 14.862C18.4793 14.6707 18.7167 14.575 19 14.575C19.2833 14.575 19.5207 14.6707 19.712 14.862C19.904 15.054 20 15.2917 20 15.575V17.575C20 18.125 19.8043 18.596 19.413 18.988C19.021 19.3793 18.55 19.575 18 19.575H6Z" fill="#1C1B1F" />
                          </svg>
                          {i18nJs.t('Click to upload or drag & drop Maximum file size 5 MB')}
                        </>
                      )}
                    </Button>
                    <input type="file" className='image-uploaded-box' title="" accept="image/*"  name="myfile"  value={this.state.filesUploadFlag || ""} test-id="handleimage" onChange={(event: React.ChangeEvent<HTMLInputElement>) => {
                      this.handleFileChangeProfile(event)
                    }} />
                    {!this.state.imagesProfile && <span className='error-text' style={{ color: 'red' }}>
                      {this.state.errorImg === true ? <span className='error'>{i18nJs.t('Image is Required')}</span> : ""}
                    </span>}
                  </Box>
                  <Box className="imageBlock">
                    <Typography className='imageBlockText'><svg xmlns="http://www.w3.org/2000/svg" width="24" height="24" viewBox="0 0 24 24" fill="none">
                      <mask id="mask0_528_15091" maskUnits="userSpaceOnUse" x="0" y="0" width="24" height="24">
                        <rect width="24" height="24" fill="#D9D9D9" />
                      </mask>
                      <g mask="url(#mask0_528_15091)">
                        <path d="M9 12.5H10V10.5H11C11.2833 10.5 11.5208 10.4042 11.7125 10.2125C11.9042 10.0208 12 9.78333 12 9.5V8.5C12 8.21667 11.9042 7.97917 11.7125 7.7875C11.5208 7.59583 11.2833 7.5 11 7.5H9V12.5ZM10 9.5V8.5H11V9.5H10ZM13 12.5H15C15.2833 12.5 15.5208 12.4042 15.7125 12.2125C15.9042 12.0208 16 11.7833 16 11.5V8.5C16 8.21667 15.9042 7.97917 15.7125 7.7875C15.5208 7.59583 15.2833 7.5 15 7.5H13V12.5ZM14 11.5V8.5H15V11.5H14ZM17 12.5H18V10.5H19V9.5H18V8.5H19V7.5H17V12.5ZM8 18C7.45 18 6.97917 17.8042 6.5875 17.4125C6.19583 17.0208 6 16.55 6 16V4C6 3.45 6.19583 2.97917 6.5875 2.5875C6.97917 2.19583 7.45 2 8 2H20C20.55 2 21.0208 2.19583 21.4125 2.5875C21.8042 2.97917 22 3.45 22 4V16C22 16.55 21.8042 17.0208 21.4125 17.4125C21.0208 17.8042 20.55 18 20 18H8ZM4 22C3.45 22 2.97917 21.8042 2.5875 21.4125C2.19583 21.0208 2 20.55 2 20V6H4V20H18V22H4Z" fill="#1C1B1F" />
                      </g>
                    </svg>{this.state?.fileData?.name}</Typography>
                  </Box>
                </Box>
                {this.state.open ?
                  <Alert action={
                    <IconButton
                      test-id="discard_profile_modal_submit"
                      aria-label="close"
                      color="inherit"
                      size="small"
                      onClick={() => {
                        this.setState({ open: false })
                      }}
                    >
                      <CloseIcon fontSize="inherit" />
                    </IconButton>
                  }
                    severity="error">
                    {i18nJs.t('Sorry, an error has occurred while uploading "Raffels image".Please try again')}
                  </Alert> : null
                }
                {this.state.fileData && (
                  <Box className="process-block">
                    <LinearProgress variant="determinate" value={this.state.progressUpload} className='process-bar-clr' />
                    <Box className="process-info">
                      <Box className="process-info-image">
                        <img src={URL.createObjectURL(this.state.fileData)} />
                      </Box>
                      <Box className="process-info-content">
                        <h6>{this.state.fileData.name}</h6>
                        <span>{this.state.progressUpload}% uploaded</span>
                      </Box>
                      <Box id='processBlockClose' onClick={() => { this.setState({ progressUpload: null, fileData: null, filesUploadFlag:"" }) }} className="process-block-close">
                        <svg xmlns="http://www.w3.org/2000/svg" height="1em" viewBox="0 0 384 512"><path d="M342.6 150.6c12.5-12.5 12.5-32.8 0-45.3s-32.8-12.5-45.3 0L192 210.7 86.6 105.4c-12.5-12.5-32.8-12.5-45.3 0s-12.5 32.8 0 45.3L146.7 256 41.4 361.4c-12.5 12.5-12.5 32.8 0 45.3s32.8 12.5 45.3 0L192 301.3 297.4 406.6c12.5 12.5 32.8 12.5 45.3 0s12.5-32.8 0-45.3L237.3 256 342.6 150.6z" /></svg>
                      </Box>
                    </Box>
                  </Box>
                )}
              </Box>
            </Box>
            <Box className="modalFooter">
              <Button id='discardId' onClick={() => { this.setState({ progressUpload: null,fileUploadModalVisible: false, imagesProfile: {},filesUploadFlag:"", fileData: null }) }} type="button" variant="outlined" className="discardBtn">{i18nJs.t('Discard')}</Button>
              <Button id='discardProfileModalSubmit' type="button" className="uploadBtn" variant="outlined" disabled={this.state.imageProfileFlag || !this.state.fileData} onClick={() => { this.editProfileDetailsImages()}}>{this.state.imageProfileFlag ? <CircularProgress size={30} className='text-white' /> : i18nJs.t('Upload now')}</Button>
            </Box>
          </Box>
        </ModalStyle>
      </Dialog>)
  }

  renderErrorMessage = (name:string) => {
    return(
      <span className='error-text' style={{ color: 'red' }}>
        <ErrorMessage name={name} component="div" className="error helloclass" />
      </span>
    )
  }

  renderImageName = (URL:string) => {
    const aray = URL && URL.split("/");
    return aray && aray[aray?.length - 1]
  }
  
  edituserForm = () => {
    return <Formik
      initialValues={{
        fname: this.state.dataUser?.attributes?.first_name || "",
        lname: this.state.dataUser?.attributes?.last_name || "",
        email: this.state.dataUser?.attributes?.email || "",
        phno: this.state.dataUser?.attributes?.phone_number || this.state.dataUser?.attributes?.full_phone_number || "",
        date:  moment(this.state.dataUser?.attributes?.date_of_birth || new Date(), "DD-MM-YYYY").toDate(),
        qualification:this.state.dataUser?.attributes?.qualification || "",
        experience:this.state.dataUser?.attributes?.experience || "",
        biography:this.state.dataUser?.attributes?.biography || "",
        major:this.state.dataUser?.attributes?.major || "",
        department:this.state.dataUser?.attributes?.department || "",
        card:{name:this.renderImageName(this.state.dataUser?.attributes?.identification_card?.url)},
        resume:{name:this.renderImageName(this.state.dataUser?.attributes?.resume?.url)}
      }}
      data-test-id="submit-button"
      onSubmit={(values) => {
        if (this.state.onSubmitFlag) {
          this.editLecturerProfileDetails(values)
        }
      }}
      validationSchema={validationSchema}
    >
      {({ handleSubmit,values,setFieldValue }) => {
        return (
          <FormStyle>
            <form onSubmit={handleSubmit}>
              <Box className="profileBlockHeading">
                <Typography className='basicInformation'>{i18nJs.t('Basic Information')}</Typography>
              </Box>
              <Box className="profileBlockContent">
                <Box className="profileFormBlock">
                  <Grid container spacing={3}>
                    <Grid item xs={12} md={6}>
                      <Box className="formGroup">
                        <Typography className='formLabel'>{i18nJs.t('First Name')}*</Typography>
                        <Field type="text" id="fname" name="fname" placeholder="" disabled={this.state.profileEdit} />
                        {this.renderErrorMessage("fname")}
                      </Box>
                    </Grid>
                    <Grid item xs={12} md={6}>
                      <Box className="formGroup" id='checkcheckid'>
                        <Typography className='formLabel'>{i18nJs.t('Last Name')}*</Typography>
                        <Field type="text" data-test-id="boletotest" id="lname" name="lname" placeholder="" disabled={this.state.profileEdit} />
                        {this.renderErrorMessage("lname")}
                      </Box>
                    </Grid>
                    <Grid item xs={12} md={6}>
                      <Box className="formGroup">
                        <Typography className='formLabel'>{i18nJs.t('Official Email')}*</Typography>
                        <Field type="email" id="mail" name="email" placeholder="" disabled={true} />
                        {this.renderErrorMessage("email")}
                      </Box>
                    </Grid>
                   
                    <Grid item xs={12} md={6}>
                      <Box className="formGroup">
                        <Typography className='formLabel'>{i18nJs.t('Phone Number')}*</Typography>
                        <Field type="text" id="no" name="phno" placeholder="" disabled={this.state.profileEdit} />
                        {this.renderErrorMessage("phno")}
                      </Box>
                    </Grid>

                    <Grid item xs={12} md={6}>
                      <Box className="qualification*">
                        <Typography className='formLabel'>{i18nJs.t('QUALIFICATION')}*</Typography>
                        <Field type="qualification" id="qualification" name="qualification" placeholder="" disabled={this.state.profileEdit} />
                        {this.renderErrorMessage("qualification")}
                      </Box>
                    </Grid>

                    <Grid item xs={12} md={6}>
                      <Box className="formGroup">
                        <Typography className='formLabel'>{i18nJs.t('Date of birth')}*</Typography>
                        {/* <Field type="date" id="bdate" name="date" placeholder="" disabled={this.state.profileEdit} /> */}
                        <DatePicker  
                          selected={values.date}
                          id="DOBdatepicker"
                          onChange={(date: Date | null) => setFieldValue('date', date)}
                          dateFormat="dd-MM-yyyy"   
                        />
                        {this.renderErrorMessage("date")}
                      </Box>
                    </Grid>
                    <Grid item xs={12} md={6}>
                      <Box className="formGroup">
                        <Typography className='formLabel'>{i18nJs.t('Identification Card')}* <span>({i18nJs.t('Passport no')}., {i18nJs.t('National')} &amp;
                        {i18nJs.t('Resident ID')} - {i18nJs.t('Iqama')})*</span>
                        </Typography>
                        <Box className="upload-btn">
                          <Box className="upload-btn-wrapper">
                            <Button className="identificationCard">{values.card?.name ? values.card?.name : "Upload here"}</Button>
                            <input
                                accept="image/*"
                                type="file"
                                name="card"
                                id="card"
                                data-test-id="uploadField"
                                onChange={(event: any) => {
                                  setFieldValue(
                                    "card",
                                    event.currentTarget.files[0]
                                  );
                                }}
                                disabled={this.state.profileEdit}
                                title=""
                              />
                            {this.renderErrorMessage("card")}
                            <svg id="iddownload" className={this.state.profileEdit ? "hideIcon" : ""} xmlns="http://www.w3.org/2000/svg" height="1em" viewBox="0 0 448 512"><path d="M246.6 9.4c-12.5-12.5-32.8-12.5-45.3 0l-128 128c-12.5 12.5-12.5 32.8 0 45.3s32.8 12.5 45.3 0L192 109.3V320c0 17.7 14.3 32 32 32s32-14.3 32-32V109.3l73.4 73.4c12.5 12.5 32.8 12.5 45.3 0s12.5-32.8 0-45.3l-128-128zM64 352c0-17.7-14.3-32-32-32s-32 14.3-32 32v64c0 53 43 96 96 96H352c53 0 96-43 96-96V352c0-17.7-14.3-32-32-32s-32 14.3-32 32v64c0 17.7-14.3 32-32 32H96c-17.7 0-32-14.3-32-32V352z" /></svg>
                          </Box>
                        </Box>
                      </Box>
                    </Grid>

                    <Grid item xs={12} md={6}>
                      <Box className="formGroup">
                      <Typography className='formLabel'>{i18nJs.t('RESUME')}</Typography>

                        <Box className="upload-btn">
                          <Box className="upload-btn-wrapper">
                            <Button className="identificationCard">{values.resume?.name ? values.resume?.name : "Upload here"}</Button>
                              <input
                                accept="image/*"
                                type="file"
                                name="resume"
                                id="resume"
                                data-test-id="uploadField"
                                onChange={(event: any) => {
                                  setFieldValue(
                                    "resume",
                                    event.currentTarget.files[0]
                                  );
                                }}
                                disabled={this.state.profileEdit}
                                title=""
                              />
                             {this.renderErrorMessage("resume")}
                            <svg className={this.state.profileEdit ? 'hideIcon' : ""} id="iddownloadsecond" xmlns="http://www.w3.org/2000/svg" height="1em" viewBox="0 0 448 512"><path d="M246.6 9.4c-12.5-12.5-32.8-12.5-45.3 0l-128 128c-12.5 12.5-12.5 32.8 0 45.3s32.8 12.5 45.3 0L192 109.3V320c0 17.7 14.3 32 32 32s32-14.3 32-32V109.3l73.4 73.4c12.5 12.5 32.8 12.5 45.3 0s12.5-32.8 0-45.3l-128-128zM64 352c0-17.7-14.3-32-32-32s-32 14.3-32 32v64c0 53 43 96 96 96H352c53 0 96-43 96-96V352c0-17.7-14.3-32-32-32s-32 14.3-32 32v64c0 17.7-14.3 32-32 32H96c-17.7 0-32-14.3-32-32V352z" /></svg>
                          </Box>
                        </Box>
                      </Box>
                    </Grid>
                    <Grid item xs={12} md={6}>
                      <Box className="formGroup" id='heytest' data-test-id="heytest">
                        <Typography className='formLabel'>{i18nJs.t('DEPARTMENT')}*</Typography>
                        <Field type="text" id="department" name="department" placeholder="" disabled={this.state.profileEdit} />
                        {this.renderErrorMessage("department")}
                      </Box>
                    </Grid>
                    <Grid item xs={12} md={6}>
                      <Box className="formGroup" id='majorBox' data-test-id="testtestid">
                        <Typography className='formLabel' id='majors'>{i18nJs.t('MAJORS')}*</Typography>
                        <Field type="text" id="major" name="major" placeholder="" disabled={this.state.profileEdit} />
                        {this.renderErrorMessage("major")}
                      </Box>
                    </Grid>
                    <Grid item xs={12} md={6}>
                      <Box className="formGroup" id="errorkiid">
                        <Typography className='formLabel' >{i18nJs.t('EXPERIENCE')}*</Typography>
                        <Field type="text" id="experience" name="experience" placeholder="" disabled={this.state.profileEdit} />
                        {this.renderErrorMessage("experience")}
                      </Box>
                    </Grid>
                    
                    <Grid item xs={12} md={6}>
                      <Box className="formGroup">
                        <Typography className='formLabel'>{i18nJs.t('BIOGRAPHY')}*</Typography>
                        <Field type="text" id="biography" name="biography" placeholder="" disabled={this.state.profileEdit} />
                         {this.renderErrorMessage("biography")}
                      </Box>
                    </Grid>
                  </Grid>

                </Box>
              </Box>
              <Box className="profileBlockHeading">
                <h3 className='mb-0'>{i18nJs.t('Account')}</h3>
                <h4 className='mt-0 mb-05 f-400'>{this.state.dataUser?.attributes?.email}</h4>
              </Box>
              <Button type='button' id='account' className='AccountBtn' data-test-id="account-edit" onClick={() => this.onClickProfileEdit()} variant="outlined">{i18nJs.t('Change password')}</Button>

              <Box className="profileButton">

                {this.state.profileEdit ? (
                  <Button type='button' id='editProfile' className='EditProfile backBtn' onClick={() => this.onClickChangeDisabled()} variant="outlined">{i18nJs.t('Edit Profile')}</Button>

                ) : (
                  <Button
                    variant="outlined"
                    data-test-id="saveprofile-modal-save-changes"
                    id='saveChange'
                    type='submit'
                    className="EditProfile"
                    onClick={
                      () => {
                        this.setState({ onSubmitFlag: true })
                      }
                    }>{i18nJs.t('Save changes')}</Button>
                )}
              </Box>
            </form>
          </FormStyle>)
      }}
    </Formik>
  }
  // Customizable Area End
  render() {
    // Customizable Area Start
    // Customizable Area End
    return (
      // Customizable Area Start

      <ThemeProvider theme={theme}>
        <NavigationMenu id={this.props.id} navigation={this.props.navigation} title='User Profile'>
          {this.state.isloading ? 
          (<div className="home-category-block-content-norecord-loader"><CircularProgress style={{color:'#205e83'}}/></div>)
           : 
           (
          <PaperStyle style={{ width: "100%", overflow: "hidden" }} >
            <Box className="content-wrapper">
              <Box className="profile-block" >
                <Typography className="profileHeading">{i18nJs.t('Profile Picture')}</Typography>
                <Box className="profileInfo">
                  <Box className="profileInfoImage">
                    {this.state.dataUser?.attributes?.profile_picture?.url && <img className='profileImage' style={{objectFit:'cover'}} src={baseURL + this.state.dataUser?.attributes?.profile_picture?.url} alt='profile-image' />}
                  </Box>
                  <Box>
                    <Box className="uploadFileBtn">
                      <Box id='uploadImageModal' data-test-id="upload_image_modal" onClick={() => { this.setState({ fileUploadModalVisible: !this.state.fileUploadModalVisible }) }} className="uploadFile">
                        <svg xmlns="http://www.w3.org/2000/svg" height="1em" viewBox="0 0 448 512"><path d="M246.6 9.4c-12.5-12.5-32.8-12.5-45.3 0l-128 128c-12.5 12.5-12.5 32.8 0 45.3s32.8 12.5 45.3 0L192 109.3V320c0 17.7 14.3 32 32 32s32-14.3 32-32V109.3l73.4 73.4c12.5 12.5 32.8 12.5 45.3 0s12.5-32.8 0-45.3l-128-128zM64 352c0-17.7-14.3-32-32-32s-32 14.3-32 32v64c0 53 43 96 96 96H352c53 0 96-43 96-96V352c0-17.7-14.3-32-32-32s-32 14.3-32 32v64c0 17.7-14.3 32-32 32H96c-17.7 0-32-14.3-32-32V352z" /></svg>
                        <Typography className='placeText'>{i18nJs.t('Upload picture')}</Typography>
                      </Box>
                      <button className="binWrapper" id='deletepictureBtn' data-test-id="delete-picture-button" disabled={this.state.dataUser?.attributes?.profile_picture?.url ? false : true} onClick={() => this.deleteLecturePicture()}>
                        <svg xmlns="http://www.w3.org/2000/svg" width="20" height="20" viewBox="0 0 20 20" fill="none">
                          <path fillRule="evenodd" clipRule="evenodd" d="M12.3243 2.74167L12.916 3.33333H14.9993C15.4577 3.33333 15.8327 3.70833 15.8327 4.16667C15.8327 4.625 15.4577 5 14.9993 5H4.99935C4.54102 5 4.16602 4.625 4.16602 4.16667C4.16602 3.70833 4.54102 3.33333 4.99935 3.33333H7.08268L7.67435 2.74167C7.82435 2.59167 8.04102 2.5 8.25768 2.5H11.741C11.9577 2.5 12.1743 2.59167 12.3243 2.74167ZM4.99935 15.8333C4.99935 16.75 5.74935 17.5 6.66602 17.5H13.3327C14.2493 17.5 14.9993 16.75 14.9993 15.8333V7.5C14.9993 6.58333 14.2493 5.83333 13.3327 5.83333H6.66602C5.74935 5.83333 4.99935 6.58333 4.99935 7.5V15.8333ZM7.49935 7.5H12.4993C12.9577 7.5 13.3327 7.875 13.3327 8.33333V15C13.3327 15.4583 12.9577 15.8333 12.4993 15.8333H7.49935C7.04102 15.8333 6.66602 15.4583 6.66602 15V8.33333C6.66602 7.875 7.04102 7.5 7.49935 7.5Z" fill="#DC2626" />
                        </svg>
                        <Typography className='placeText'>{i18nJs.t('Delete picture')}</Typography>
                      </button>
                    </Box>
                  </Box>
                </Box>
              </Box>
              <div className="profile-conetnt">
                {this.edituserForm()}
              </div>

              {this.sucessModalVisible()}
              {this.modalDeleteVisible()}
            </Box>

            {this.fileUploadModalVisible()}


          </PaperStyle>
           )}
        </NavigationMenu>
      </ThemeProvider>
      // Customizable Area End

    )
  }
}

// Customizable Area Start

const PaperStyle = styled(Paper)({
  "& .profileHeading": {
    fontSize: "18px",
    fontWeight: 700,
    margin: " 0 0 15px",
    fontFamily: "Corbel V2"
  },
  "& .profileInfo": {
    display: "flex",
    alignItems: "center",
    gridGap: "20px",
    gap: "20px",
    // background: "#F8FAFC",
    padding: "0px",
    borderRadius: "7px",
    // border: "1px solid #94A3B8"
  },
  "& .profileInfoImage": {
    width: "100px",
    height: "100px",
    background: "#8d9db170",
    display: "flex",
    justifyContent: "center",
    alignItems: "center",
    borderRadius: "5px",
    border: '1px solid #37657F',

  },
  "& .uploadFileBtn": {
    position: "relative" as const
  },
  "& .binWrapper": {
    display: "flex",
    alignItems: "center",
    width: "126px",
    marginLeft: "18px",
    cursor: "pointer",
    border:"none",
    background:"transparent",
    "&:disabled":{
      cursor: 'not-allowed'
    },
    "& svg": {
      marginRight: '8px'
    },
    "& .placeText": {
      color: '#DC2626'
    }
  },
  "& .profileblock": {
    marginBottom: "20px",
  },
  "& .profileImage": {
    width: "100%",
    height: "100%",
    borderRadius: "5px",
    objectFit: "cover"
  },
  "& .uploadFile": {
    padding: "10px 16px",
    borderRadius: "8px",
    border: "1px solid #37657F",
    width: "200px",
    display: "flex",
    alignItems: "center",
    justifyContent: "space-evenly",
    fontSize: "16px",
    marginBottom: "23px",
    position: "static",
    marginTop: "6px",
    height: '40px',
    background: '#f8f7ff',
    cursor: "pointer",
    fontFamily: "Corbel V2",
    '& svg': {
      fill: '#808080',
      fontSize: '16px',
      marginRight: '8px'
    }
  },
  "& .placeText": {
    fontSize: '13px',
    fontFamily: "Corbel V2",
  },

})
const FormStyle = styled(Box)({
  "& .profileBlockHeading": {
    paddingTop: '25px',
  },
  "& .basicInformation": {
    margin: "0 0 15px",
    color: "#000",
    fontSize: "18px",
    fontStyle: "normal",
    fontWeight: 700,
    lineHeight: "normal",
    letterSpacing: "-0.198px",
    fontFamily: "Corbel V2"
  },
  "& .formGroup": {
    padding: '0px'
  },
  "& .formLabel": {
    display: "block",
    marginBottom: "15px",
    color: "#334155",
    fontSize: "14px",
    fontStyle: "normal",
    fontWeight: 400,
    lineHeight: "24px"
  },
  "& .profileBlockContent": {
    background: "#F8FAFC",
    padding: "30px",
    borderRadius: "10px",
    border: "1px solid #94A3B8"
  },
  "& .profileFormBlock input": {
    padding: "12px",
    borderRadius: "7px",
    width: "100%",
    border: "1px solid #CBD5E1",
    backgroundColor: "#fff",
    fontSize: "14px",
    fontStyle: "normal",
    fontWeight: 400,
    lineHeight: "22px",
    color: "#37657F"
  },
  "& .AccountBtn,.backBtn": {
    backgroundColor: "#F1F5F9",
    color: "#205e83",
    maxWidth: "180px",
    padding: "5px",
    width: "100%",
    fontSize: "16px",
    fontWeight: 700,
    lineHeight: "24px",
    borderRadius: "8px",
    border: "1px solid #37657F",
    fontFamily: '"Corbel V2"',
    letterSpacing: "0.5px",
    cursor: "pointer",
    minHeight: "50px",
    textTransform: 'none'
  },
  "& .AccountBtn:hover": {
    backgroundColor: '#205e83',
    color: '#fff'
  },
  "& .backBtn:hover": {
    backgroundColor: '#205e83',
    color: '#fff'
  },
  "& .profileButton": {
    display: "flex",
    justifyContent: "flex-end",
    gap: "20px",
    marginTop: "35px",
    flexWrap: "wrap",
  },
  "& .EditProfile": {
    maxWidth: "170px",
    background: "#205e83 !important",
    border: "none",
    borderRadius: "8px",
    color: "#fff",
    fontFamily: '"Corbel V2"',
    display: "flex",
    alignItems: "center",
    justifyContent: "center",
    fontSize: "16px",
    fontStyle: "normal",
    fontWeight: 700,
    lineHeight: "24px",
    width: '100%',
    textTransform:"none"
  },
  "& .delete-btn:hover": {
    backgroundColor: "#DC2626",
    color: "#fff",
    border: "1px solid #DC2626",
    "& svg": {
      fill: "#fff"
    }
  },
  "& .delete-btn": {
    backgroundColor: "transparent",
    color: "#DC2626",
    border: "1px solid #DC2626",
    textTransform: 'none',
    "& svg": {
      fill: '#DC2626'

    }
  },
  "& .identificationCard":{
    background: "#fff",
    padding: "12px",
    borderRadius: "7px",
    width: "100%",
    border: "1px solid lightgray",
    textAlign: "left",
    color: "gray",
    minHeight: "50px",
    justifyContent:"flex-start",
    textTransform:"none",
    fontFamily: "Corbel V2"
  },
  "& .identificationSpan":{
    fontSize:"13px"
  },
  "& .hideIcon":{
    display:"none"
  }
})
const ModalStyle = styled(Box)({
  position: "fixed",
  top: "50%",
  left: "50%",
  transform: "translate(-50%, -50%)",
  width: "100%",
  zIndex: 7,

  "& .closeBtn": {
    border: "none",
    fontSize: "24px",
    cursor: "pointer",
    padding: "0",
    width: "40px",
    minWidth: "40px",
    height: "40px",
    lineHeight: "35px",
    color: "#1C1B1F",
    borderRadius: "6px",
    background: "rgba(217, 217, 217, 0.50)"
  },
  "& .modalDialog": {
    maxWidth: "1280px",
    margin: "0 auto",
    background: "#fff",
    borderRadius: "10px",
    padding: "40px",
    "@media (max-width: 1366px)": {
      maxWidth: '1000px'
    },
    "@media (max-width: 1199px)": {
      maxWidth: '850px'
    },
    "@media (max-width: 991px)": {
      maxWidth: '650px'
    }

  },
  "& .modalHeader": {
    display: "flex",
    alignItems: "baseline",
    justifyContent: "space-between",
    padding: '0px',
    "& span": {
      fontSize: '32px'
    }
  },
  "& .blockHeading": {
    padding: '0px',
    "& h3": {
      margin: "0 0 5px",
      color: "#334155",
      fontSize: "32px",
      fontStyle: "normal",
      fontWeight: 700,
      lineHeight: "normal",
      display: "flex",
      alignItems: "center",
      letterSpacing: "-0.198px"
    },
    "& p": {
      fontSize: "16px",
      fontStyle: "normal",
      fontWeight: 400,
      lineHeight: "normal",
      paddingTop: "5px",
      margin: "0",
      color: "#37657F"
    }
  },
  "& .modalBody": {
    padding: "40px 0", position: "relative"
  },
  "& .image-uploaded-box":{
    position: 'absolute',
    top: 0,
    height: '180px',
    opacity: 0,
    cursor:"pointer"
  },
  "& .modalBody::after": {
    content: '""',
    position: "absolute",
    borderBottom: "1px solid #E2E8F0",
    bottom: "-25px",
    left: "50%",
    width: "100%",
    transform: "translate(-50%)"
  },
  "& .dargBlock": {
    border: "1px dashed #94A3B8",
    margin: "0",
    borderRadius: "5px",
    height: "180px",
    textAlign: "center",
    background: "#F8FAFC",
    cursor: "pointer",
    position: "relative"
  },
  "& .uploadBtnWrapper": {
    position: "relative",
    overflow: "hidden",
    display: "inline-block",
    width: "100%",
    height: "180px",
    lineHeight: "150px"
  },
  "& .dropButton": {
    border: "none",
    color: "#334155",
    background: "transparent",
    padding: "0",
    textAlign: "center",
    height: "180px",
    fontSize: "16px",
    fontStyle: "normal",
    fontWeight: 400,
    lineHeight: "normal",
    textTransform: 'none',
    "& .sizeText": {
      display: "block",
      fontSize: "14px",
      fontStyle: "normal",
      fontWeight: 400,
      lineHeight: "normal",
      color: "#37657F",
      padding: "9px 0 0"
    },
    "& span": {
      flexDirection: 'column',

    }
  },
  "& .imageBlock": {
    position: "absolute",
    bottom: "25px",
    right: "35px",
    background: "#CBD5E1",
    borderRadius: "10px",
    padding: "15px 10px",
    "& .imageBlockText": {
      margin: "0",
      color: "#37657F",
      fontSize: "14px",
      fontStyle: "normal",
      fontWeight: 400,
      lineHeight: "normal"
    },
    "& svg": {
      verticalAlign: "middle",
      marginRight: "10px",
      fontSize: "18px"
    }
  },
  "& .modalFooter": {
    margin: "0",
    padding: "40px 0 0",
    textAlign: "right",
    display: "flex",
    justifyContent: "flex-end",
    gridGap: "20px",
    gap: "20px",
    flexWrap: "wrap"
  },
  "& .discardBtn": {
    maxWidth: "100px",
    minWidth: "100px",
    border: "1px solid #94A3B8",
    textTransform: 'none',
    backgroundColor: "#F1F5F9",
    color: "#205e83",
    padding: "12px",
    width: "100%",
    fontWeight: 700,
    lineHeight: "24px",
    fontFamily: "Corbel V2"
  },
  "& .discardBtn:hover": {
    backgroundColor: "#205e83",
    color: "#fff"
  },
  "& .uploadBtn": {
    background: '#205e83',
    color: "#fff",
    maxWidth:" 150px",
    minWidth: "150px",
    fontSize: "16px",
    fontFamily: "Corbel V2",
    textTransform:"none",
    fontWeight: 700,
  },
  "& .profile_upload_modal_arabic .modalHeader":{
    flexDirection: "row-reverse",
  },
  "& .profile_upload_modal_arabic .modalFooter":{
    flexDirection: "row-reverse",
  }
})
const DeleteModalStyle = styled(Box)({
  position: "fixed",
  top: "50%",
  left: "50%",
  transform: "translate(-50%, -50%)",
  width: "100%",
  zIndex: 7,
  "@media (max-width: 575px)": {
    maxWidth: '350px'
  },
  "& .modalDialog": {
    maxWidth: "600px",
    margin: "0 auto",
    background: "#fff",
    borderRadius: "8px",
    boxShadow: "0px 8px 32px 0px rgba(0, 0, 0, 0.06), 0px 4px 8px 0px rgba(0, 0, 0, 0.03), 0px 25px 50px 0px rgba(0, 0, 0, 0.09)"
  },
  "& .modalHeader": {
    textAlign: "right",
    padding: "40px 40px 16px 40px !important"
  },
  "& .closeDeleteBtn": {
    border: "none",
    cursor: "pointer",
    padding: "0",
    color: "#1C1B1F",
    borderRadius: "6px",
    background: "rgba(217, 217, 217, 0.50)",
    width: "40px",
    minWidth: "40px",
    height: "40px",
    textAlign: 'right',
    "& span": {
      fontSize: '32px',
    },
  },
  "& .modalBody": {
    padding: "0 40px",
    position: "relative",
    "& h5": {
      margin: "0 0 5px",
      color: "#334155",
      fontSize: "24px",
      fontStyle: "normal",
      fontWeight: 700,
      lineHeight: "32px",
      letterSpacing: "-0.12px"
    },
    "& p": {
      margin: "16px 0 25px",
      fontSize: "16px",
      fontStyle: "normal",
      fontWeight: 400,
      lineHeight: "normal",
      color: "#37657F"
    }
  },
  "& .modalBody::after": {
    content: '""',
    position: "absolute",
    borderBottom: "1px solid #E2E8F0",
    bottom: "-25px",
    left: "50%",
    width: "87%",
    transform: "translate(-50%)"
  },
  "& .cancelBtn": {
    maxWidth: "150px",
    minWidth: "150px",
    fontSize: "16px",
    borderRadius: "5px",
    border: "1px solid #37657F",
    fontFamily: '"Corbel V2"',
    letterSpacing: "0.5px",
    cursor: "pointer",
    minHeight: "50px",
    backgroundColor: "#F1F5F9",
    color: "#205e83",
    width: "100%",
    fontWeight: 700,
    lineHeight: "24px",
    textTransform: 'none'
  },
  "& .cancelBtn:hover": {
    backgroundColor: "#205e83",
    color: "#fff"
  },
  "& .deleteBtn": {
    maxWidth: "150px",
    minWidth: "150px",
    fontSize: "16px",
    backgroundColor: "#DC2626",
    color: "#fff",
    border: "1px solid #DC2626",
    textTransform: 'none',
    fontFamily: "Corbel V2",
    fontWeight:700
  },
  "& .deleteBtn:hover": {
    backgroundColor: "transparent",
    color: "#DC2626",
    border: "1px solid #DC2626"
  },
  "& .modalFooter": {
    margin: "0",
    padding: "20px 40px",
    display: "flex",
    justifyContent: "flex-end",
    gap: "20px",
    flexWrap: "wrap"
  }

})
const VisibleStyle = styled(Box)({
  "& .visibleClose": {
    border: "none",
    cursor: "pointer",
    padding: "0",
    color: "#1C1B1F",
    borderRadius: "6px",
    background: "rgba(217, 217, 217, 0.50)",
    width: "40px",
    minWidth: "40px",
    height: "40px"
  },
  "& .modalHeader": {
    textAlign: "right",
    padding: "40px 40px 16px 40px",
    "& span": {
      fontSize: '32px',
    },
  },
  "& .dialogContentBody": {
    padding: "0 40px",
    marginBottom: "20px",
    "& h5": {
      margin: "0 0 5px",
      color: "#334155",
      fontSize: "24px",
      fontStyle: "normal",
      fontWeight: 700,
      lineHeight: "32px",
      letterSpacing: "-0.12px"
    },
    "& p": {
      margin: "16px 0 25px",
      fontSize: "16px",
      fontStyle: "normal",
      fontWeight: 400,
      lineHeight: "normal",
      color: "#37657F"
    },
    
    
  }
})
