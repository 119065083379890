import React from "react";

// Customizable Area Start
import { Box, Button, Dialog, Divider, Grid, LinearProgress, Paper, Typography } from "@material-ui/core";
import { createTheme, ThemeProvider, withStyles } from "@material-ui/core/styles";
import NavigationMenu from "../../dashboard/src/NavigationMenu.web";
import { paymentImage } from "../../contentmanagement3/src/assets";
import { CardNumberElement, CardExpiryElement, CardCvcElement, Elements, ElementsConsumer } from '@stripe/react-stripe-js';
import "matchmedia-polyfill";
import "matchmedia-polyfill/matchMedia.addListener";
import { stripePromise } from './PaymentsController';
const baseURL = require("../../../framework/src/config.js").baseURL
import PreventBackNavigation from "../../../components/src/PreventBackNavigation";

// Customizable Area End



import PrivacyPolicyController, {
    Props
} from "../../stripepayments/src/PaymentsController";
import i18nJs from "../../../components/src/TranslateLanguage";

const theme = createTheme({
    palette: {
        primary: {
            main: "#0000ff",
            contrastText: "#fff"
        }
    }
});

export class Payments extends PrivacyPolicyController {
    constructor(props: Props) {
        super(props);
    }


    render() {

        const { classes } = this.props
        const { uploaded_Payment_documents, uploaded_Payment_documents1 } = this.state

        return (
            <>
             {/* <PreventBackNavigation/> */}
            <ThemeProvider theme={theme}>
                <NavigationMenu
                    id={this.props.id}
                    navigation={this.props.navigation}
                    title="Payments"
                >
                    <Paper elevation={0} style={{ width: "100%", overflow: "hidden" }}>

                        <Box component="div" className={classes.maindiv}>
                            <Grid container style={{ justifyContent: 'space-between' }}>
                                <Grid item lg={5} xs={12}  >
                                    <Grid item md={12} sm={12}>
                                        <Typography className={classes.textClass}>{i18nJs.t('Cart')}</Typography>
                                    </Grid>
                                    <Grid component="div" className={classes.display} item md={12} xs={12} sm={12}>
                                        <Box component="div" style={{ width: "200px", height: "auto", }} className={classes.imageBlock}>
                                            {this.state.paymentsDetails.data?.attributes.course_image?.url ?
                                                <img className={classes.imagecss} src={baseURL + this.state.paymentsDetails.data.attributes.course_image.url} alt='No image available' style={{ width: "100%", height: "100%" }} />
                                                :
                                                <div style={{ textAlign: "center", width: "100%", height: "100%", borderRight: "0.1px solid gray", }}>
                                                    No image available
                                                </div>}
                                        </Box>
                                        <Box className={classes.setitems} component="div">
                                            <Typography className={classes.textClass}>{this.state.paymentsDetails.data?.attributes.course_name}</Typography>
                                            <Typography component='span'>{this.getCourseCategoryPayments(this.state.paymentsDetails.data?.attributes.category_id)}</Typography>
                                            <Typography><svg xmlns="http://www.w3.org/2000/svg" width="16" height="15" viewBox="0 0 16 15" fill="none">
                                                <path d="M8 0L9.79611 5.52786H15.6085L10.9062 8.94427L12.7023 14.4721L8 11.0557L3.29772 14.4721L5.09383 8.94427L0.391548 5.52786H6.20389L8 0Z" fill="#F59E0B" />
                                            </svg> <Typography className={classes.spancss} component="span"> {this.state.paymentsDetails?.data?.attributes?.average_rating}</Typography></Typography>
                                            <Box className={classes.setflexitems} component='div'>
                                                <Typography><svg xmlns="http://www.w3.org/2000/svg" width="20" height="20" viewBox="0 0 20 20" fill="none">
                                                    <path fillRule="evenodd" clipRule="evenodd" d="M6.66667 1.66699C5.75 1.66699 5 2.41699 5 3.33366V5.97533C5 6.41699 5.175 6.84199 5.49167 7.15866L8.33333 10.0003L5.48333 12.8503C5.175 13.167 5 13.592 5 14.0337V16.667C5 17.5837 5.75 18.3337 6.66667 18.3337H13.3333C14.25 18.3337 15 17.5837 15 16.667V14.0337C15 13.592 14.825 13.167 14.5167 12.8587L11.6667 10.0003L14.5083 7.16699C14.825 6.85033 15 6.42533 15 5.98366V3.33366C15 2.41699 14.25 1.66699 13.3333 1.66699H6.66667ZM13.3333 13.7503V15.8337C13.3333 16.292 12.9583 16.667 12.5 16.667H7.5C7.04167 16.667 6.66667 16.292 6.66667 15.8337V13.7503L10 10.417L13.3333 13.7503ZM6.66667 4.16699V6.25033L10 9.58366L13.3333 6.25033V4.16699C13.3333 3.70866 12.9583 3.33366 12.5 3.33366H7.5C7.04167 3.33366 6.66667 3.70866 6.66667 4.16699Z" fill="#37657F" />
                                                </svg> <Typography component='span'>{this.convertMinutesToHoursAndMinutes(this.state.paymentsDetails.data?.attributes.total_duration_of_lessons)}</Typography></Typography>
                                                <Typography><svg xmlns="http://www.w3.org/2000/svg" width="20" height="20" viewBox="0 0 20 20" fill="none">
                                                    <path fillRule="evenodd" clipRule="evenodd" d="M17.4997 2.5H2.49967C1.58301 2.5 0.833008 3.25 0.833008 4.16667V14.1667C0.833008 15.0833 1.58301 15.8333 2.49967 15.8333H6.66634V16.6667C6.66634 17.125 7.04134 17.5 7.49967 17.5H12.4997C12.958 17.5 13.333 17.125 13.333 16.6667V15.8333H17.4997C18.4163 15.8333 19.158 15.0833 19.158 14.1667L19.1663 4.16667C19.1663 3.24167 18.4163 2.5 17.4997 2.5ZM16.6663 14.1667H3.33301C2.87467 14.1667 2.49967 13.7917 2.49967 13.3333V5C2.49967 4.54167 2.87467 4.16667 3.33301 4.16667H16.6663C17.1247 4.16667 17.4997 4.54167 17.4997 5V13.3333C17.4997 13.7917 17.1247 14.1667 16.6663 14.1667ZM8.74967 11.7917L12.0663 9.89167C12.6247 9.56667 12.6247 8.76667 12.0663 8.44167L8.74967 6.54167C8.19134 6.225 7.49967 6.625 7.49967 7.26667V11.0667C7.49967 11.7 8.19134 12.1083 8.74967 11.7917Z" fill="#37657F" />
                                                </svg> <Typography component='span'>{this.state.paymentsDetails?.data?.attributes?.no_of_lessons} {i18nJs.t('Sessions')}</Typography></Typography>
                                            </Box>
                                            <Box className={classes.setitems1} component='div'>
                                                <Typography>د.إ{this.state.paymentsDetails.data?.attributes.price}</Typography>
                                                <Typography data-test-id="navigate-to-categories" component='span' style={{ cursor: "pointer" }} onClick={this.goToCategories}>{i18nJs.t('Remove')}</Typography>
                                            </Box>
                                        </Box>
                                    </Grid>
                                </Grid>
                                <Grid item lg={6} xs={12} className={classes.setWidth} >
                                    <Grid item md={6} sm={12}>
                                        <Typography className={classes.textClass}>{i18nJs.t('Summary')}</Typography>
                                    </Grid>
                                    <Grid style={{ marginTop: '16px' }} item md={6} sm={12}>
                                        <Typography component='span'>{i18nJs.t('Please complete payment to use the features')}.</Typography>
                                    </Grid>
                                    <Grid className={classes.setMediaQuery} style={{ marginTop: '16px' }} item md={11} sm={12}>
                                        <Box className={classes.designflex}><Typography className={classes.designBorder}>{i18nJs.t('Verify the information before proceeding')}</Typography>
                                            <Typography><svg xmlns="http://www.w3.org/2000/svg" width="16" height="16" viewBox="0 0 16 16" fill="none">
                                                <path fillRule="evenodd" clipRule="evenodd" d="M1.33301 8.00065C1.33301 4.32065 4.31301 1.33398 7.99301 1.33398C11.6797 1.33398 14.6663 4.32065 14.6663 8.00065C14.6663 11.6807 11.6797 14.6673 7.99301 14.6673C4.31301 14.6673 1.33301 11.6807 1.33301 8.00065ZM8.66634 5.33398C8.66634 4.96732 8.36634 4.66732 7.99967 4.66732C7.63301 4.66732 7.33301 4.96732 7.33301 5.33398V8.00065C7.33301 8.36732 7.63301 8.66732 7.99967 8.66732C8.36634 8.66732 8.66634 8.36732 8.66634 8.00065V5.33398ZM7.99967 13.334C5.05301 13.334 2.66634 10.9473 2.66634 8.00065C2.66634 5.05398 5.05301 2.66732 7.99967 2.66732C10.9463 2.66732 13.333 5.05398 13.333 8.00065C13.333 10.9473 10.9463 13.334 7.99967 13.334ZM7.33301 10.0007V11.334H8.66634V10.0007H7.33301Z" fill="#0F172A" />
                                            </svg></Typography></Box>
                                        <Box style={{ marginTop: '16px' }} className={classes.setitems2} component='div'>
                                            <Typography style={{ margin: '10px 0px' }}>{i18nJs.t('Basic Price')}</Typography>
                                            <Typography component='span' style={{ margin: '10px 0px' }}>{this.state.paymentsDetails?.meta?.summary?.price}</Typography>
                                        </Box>
                                        <Box className={classes.setitems3} component='div'>
                                            <Typography style={{ margin: '10px 0px' }}>{i18nJs.t('Tax')}</Typography>
                                            <Typography component='span' style={{ margin: '10px 0px' }}>{this.state.paymentsDetails?.meta?.summary?.tax}</Typography>
                                        </Box>
                                        <hr />
                                        <Box style={{ margin: '20px 0px' }} className={classes.setitems4} component='div'>
                                            <Typography>{i18nJs.t('Subtotal')}</Typography>
                                            <Typography component='span'>{this.state.paymentsDetails?.meta?.summary?.subtotal}</Typography>
                                        </Box>
                                    </Grid>
                                    <Grid className={classes.bankdetails} item sm={12} xs={12} md={12} >
                                        <div className="course-tab">
                                            <div className="course-tab-nav">
                                                <ul className={classes.tabFlex}>
                                                    <li className={this.state.toggleTabpan === 'payoffline' ? `current` : ''}>
                                                        <span><button type="button" className={classes.tabButton} data-test-id="payoffline" onClick={() => this.setState({ toggleTabpan: "payoffline" })}>{i18nJs.t('Pay Offline')}</button></span>
                                                    </li>
                                                    <li className={this.state.toggleTabpan === 'paybytabby' ? `current` : ''}>
                                                        <span><button type="button" className={classes.tabButton} data-test-id="paybytabby" onClick={this.toggleTabbyTab}>{i18nJs.t('Pay by Tabby')}</button></span>
                                                    </li>
                                                    <li className={this.state.toggleTabpan === 'creditdebit' ? `current` : ''}>
                                                        <span><button type="button" className={classes.tabButton} data-test-id="creditdebit" onClick={this.toggleCreditDebitTab}>{i18nJs.t('Credit/Debit')}</button></span>
                                                    </li>
                                                </ul>
                                            </div>
                                            <div className="course-tab-link">
                                                {
                                                    this.state.toggleTabpan === "payoffline" &&
                                                    <div className="course_slider">
                                                        <Typography component='span'>{i18nJs.t('Bank Details')}</Typography><br />
                                                        <Typography className={classes.spaceUp} dangerouslySetInnerHTML={{ __html: (this.state.paymentsBankDetails) }} />
                                                        <Grid className={classes.uploadArea} item sm={12} xs={12} md={12} >
                                                            <Box className={classes.designflex1}>
                                                                <Typography className={classes.designBorder}>{i18nJs.t('Attachment needed')}</Typography>
                                                            </Box>
                                                            <LinearProgress variant="determinate" value={this.state.uploadProgress_uploaded_Payment_documents} className='process-bar-clr' />
                                                            <Box style={{ borderRadius: '8px', margin: '0px 0px 20px 0px' }} className={classes.setUpload}>
                                                                <Box className={classes.uploadFlex}>
                                                                    <Typography className={classes.setUploadImage} component='span'>
                                                                        <form>
                                                                            <Box>
                                                                                <Button>
                                                                                    <svg xmlns="http://www.w3.org/2000/svg" width="24" height="24" viewBox="0 0 24 24" fill="none">
                                                                                        <path d="M12 15.575C11.7167 15.575 11.4793 15.479 11.288 15.287C11.096 15.0957 11 14.8583 11 14.575V7.425L9.125 9.3C8.925 9.5 8.69167 9.6 8.425 9.6C8.15833 9.6 7.91667 9.49167 7.7 9.275C7.5 9.075 7.40433 8.83733 7.413 8.562C7.421 8.28733 7.51667 8.05833 7.7 7.875L11.3 4.275C11.4 4.175 11.5083 4.104 11.625 4.062C11.7417 4.02067 11.8667 4 12 4C12.1333 4 12.2583 4.02067 12.375 4.062C12.4917 4.104 12.6 4.175 12.7 4.275L16.3 7.875C16.5 8.075 16.5957 8.31233 16.587 8.587C16.579 8.86233 16.4833 9.09167 16.3 9.275C16.1 9.475 15.8627 9.579 15.588 9.587C15.3127 9.59567 15.075 9.5 14.875 9.3L13 7.425V14.575C13 14.8583 12.9043 15.0957 12.713 15.287C12.521 15.479 12.2833 15.575 12 15.575ZM6 19.575C5.45 19.575 4.97933 19.3793 4.588 18.988C4.196 18.596 4 18.125 4 17.575V15.575C4 15.2917 4.09567 15.054 4.287 14.862C4.479 14.6707 4.71667 14.575 5 14.575C5.28333 14.575 5.521 14.6707 5.713 14.862C5.90433 15.054 6 15.2917 6 15.575V17.575H18V15.575C18 15.2917 18.096 15.054 18.288 14.862C18.4793 14.6707 18.7167 14.575 19 14.575C19.2833 14.575 19.5207 14.6707 19.712 14.862C19.904 15.054 20 15.2917 20 15.575V17.575C20 18.125 19.8043 18.596 19.413 18.988C19.021 19.3793 18.55 19.575 18 19.575H6Z" fill="#0F172A" />
                                                                                    </svg>
                                                                                </Button>
                                                                                <input title='' name='uploaded_Payment_documents' onChange={(event: any) => {
                                                                                    this.handleFileChange(event)
                                                                                }} type="file" data-test-id="upload-file" accept="image/*, .pdf, .doc, .docx, text/plain, text/csv" />
                                                                            </Box>
                                                                        </form>
                                                                    </Typography>
                                                                    <Typography component='span' className={classes.setUploadText}>{uploaded_Payment_documents.name ? uploaded_Payment_documents.name : i18nJs.t('Upload here')} <br />{uploaded_Payment_documents.size ? `${uploaded_Payment_documents.size} KB` : '0 KB'}</Typography>
                                                                </Box>
                                                            </Box>
                                                        </Grid>
                                                        <Grid item sm={12} xs={12} md={12} >
                                                            <Button data-test-id="button1" onClick={this.uploadPaymentReceipt} fullWidth={true} variant="outlined" className={classes.submitBtn}>{i18nJs.t('Submit Now')}</Button>
                                                        </Grid>
                                                    </div>
                                                }
                                                {
                                                    this.state.toggleTabpan === "paybytabby" &&
                                                    <div className="course_slider">
                                                        <Typography component='span'>{i18nJs.t('Bank Details')}</Typography>
                                                        <Typography className={classes.spaceUp}>{i18nJs.t('Click on below mentioned link')}</Typography>
                                                        <Typography data-test-id="tabby-link" onClick={() => window.open(this.tabbyWebUrl, "noreferrer")} component='div' className={classes.spaceUp1}>{i18nJs.t('Go to Tabby')}</Typography>
                                                    </div>
                                                }
                                                {
                                                    this.state.toggleTabpan === "creditdebit" &&
                                                    <div className="course_slider">
                                                        <Typography component='span'>{i18nJs.t('Basic Information')}</Typography>
                                                        <div className={classes.creditDebitWrap}>
                                                            <form
                                                                action={`${this.state.liveUrl}?resourcePath=/v1/checkouts/${this.state.checkoutId}/payment`}
                                                                className="paymentWidgets"
                                                                data-brands="VISA MASTER AMEX"
                                                            ></form>
                                                        </div>
                                                    </div>
                                                }
                                            </div>
                                            <Dialog
                                                open={this.state.paymenttModalVisible}
                                                maxWidth={"xs"} fullWidth
                                                keepMounted
                                                aria-labelledby="alert-dialog-slide-title"
                                                aria-describedby="alert-dialog-slide-description"
                                                className={classes.mainDialog}
                                            >
                                                <div className="modal-block none-modal">
                                                    <div className="modal-dialog">
                                                        <div className={`modal-content ${i18nJs.locale === "ar" ? classes.payment_proof_modal_arabic : classes.payment_proof_modal} `} >
                                                            <div className="modal-header log-out-modal-header">
                                                                <button
                                                                    data-test-id="close_button_modal"
                                                                    onClick={() => {
                                                                        this.setState({ paymenttModalVisible: false });
                                                                    }}
                                                                    type="button"
                                                                    className="close"
                                                                >
                                                                    <span>×</span>
                                                                </button>
                                                            </div>
                                                            <div className="modal-body">
                                                                <div className="modal-icon">
                                                                    {this.state.paymentData?.status === "pending" ? (
<svg width="26" height="26" viewBox="0 0 26 26" fill="none" xmlns="http://www.w3.org/2000/svg">
                                                                        <path fill-rule="evenodd" clip-rule="evenodd" d="M12.9895 2.16602C7.00949 2.16602 2.16699 7.01935 2.16699 12.9993C2.16699 18.9793 7.00949 23.8327 12.9895 23.8327C18.9803 23.8327 23.8337 18.9793 23.8337 12.9993C23.8337 7.01935 18.9803 2.16602 12.9895 2.16602ZM13.0003 21.666C8.21199 21.666 4.33366 17.7877 4.33366 12.9993C4.33366 8.21102 8.21199 4.33268 13.0003 4.33268C17.7887 4.33268 21.667 8.21102 21.667 12.9993C21.667 17.7877 17.7887 21.666 13.0003 21.666ZM12.697 7.58268H12.762C13.1953 7.58268 13.542 7.92935 13.542 8.36268V13.281L17.7345 15.7727C18.1137 15.9893 18.2328 16.4768 18.0053 16.8452C17.7887 17.2135 17.312 17.3218 16.9437 17.1052L12.4478 14.4077C12.112 14.2127 11.917 13.8552 11.917 13.476V8.36268C11.917 7.92935 12.2637 7.58268 12.697 7.58268Z" fill="#37657F" />
                                                                        </svg>
                                                                    ) : (
                                                                        <svg xmlns="http://www.w3.org/2000/svg" width="26" height="26" viewBox="0 0 26 26" fill="none">
                                                                            <mask id="mask0_1500_39862" maskUnits="userSpaceOnUse" x="0" y="0" width="26" height="26">
                                                                                <rect width="26" height="26" fill="#D9D9D9" />
                                                                            </mask>
                                                                            <g mask="url(#mask0_1500_39862)">
                                                                                <path d="M9.31732 24.375L7.25899 20.9083L3.35898 20.0417L3.73815 16.0333L1.08398 13L3.73815 9.96667L3.35898 5.95833L7.25899 5.09167L9.31732 1.625L13.0007 3.19583L16.684 1.625L18.7423 5.09167L22.6423 5.95833L22.2632 9.96667L24.9173 13L22.2632 16.0333L22.6423 20.0417L18.7423 20.9083L16.684 24.375L13.0007 22.8042L9.31732 24.375ZM11.8632 16.8458L17.984 10.725L16.4673 9.15417L11.8632 13.7583L9.53399 11.4833L8.01732 13L11.8632 16.8458Z" fill="#37657F" />
                                                                            </g>
                                                                    </svg>
                                                                    )}


                                                                   
                                                                </div>
                                                                <h5>{this.state.paymentData?.status === "success"
                                                                    ? i18nJs.t("Payment Successfull!") : this.state.paymentData?.status === "pending" ? i18nJs.t("Payment Under Process!") : i18nJs.t("Payment Proof Submitted")}</h5>
                                                                <p>{this.state.toggleTabpan === "payoffline" ? i18nJs.t("payment_proof_submitted") : this.state.paymentData?.status === "success" ? i18nJs.t("payment_successfull") : this.state.paymentData?.status === "pending" ? i18nJs.t("payment_pending") : i18nJs.t("payment_proof_updated")}</p>
                                                                <Divider className={classes.dashedDividerStyle} />
                                                                <div className={classes.amountClass}>
                                                                    <div className={`${classes.setAmount} set_ammount`}>
                                                                        <Typography>{i18nJs.t("Amount Paid:")}</Typography>
                                                                        <Typography component='span'>د.إ{this.state.paymentsDetails.meta?.summary.subtotal}</Typography>
                                                                    </div>
                                                                    <div className={`${classes.setAmount} set_ammount`}>
                                                                        <Typography>{i18nJs.t("Paid By:")}</Typography>
                                                                        <Typography component='span'>

                                                                            {
                                                                                this.ternaryCondition(this.state.toggleTabpan === "payoffline", i18nJs.t('Offline'), this.ternaryCondition(this.state.toggleTabpan === "paybytabby", i18nJs.t('Tabby Payments'), this.ternaryCondition(this.state.toggleTabpan === "creditdebit", i18nJs.t("Credit/debit card"), "")))
                                                                            }
                                                                        </Typography>
                                                                    </div>
                                                                </div>
                                                            </div>
                                                            <div className="modal-footer profile-button" style={{ padding: "56px 40px" }}>
                                                                <button data-test-id="discard_profile_modal" type="button" className="btn button-outline-profile" onClick={this.goToCategories}>{i18nJs.t("Back")}</button>
                                                                {this.state.paymentData?.status === "success" ? (
                                                                    <button onClick={this.goToLearningPath} data-test-id="discard_profile_modal_submit" type="button" className={`${classes.setButton} btn button`} >{i18nJs.t("Start Learning")}</button>
                                                                ) : (
                                                                    <button onClick={this.goToPaymentHistory} data-test-id="discard_profile_modal_submit" type="button" className={`${classes.setButton} btn button`} >{i18nJs.t("Go to Payment History")}</button>
                                                                )
                                                                }
                                                            </div>
                                                        </div>
                                                    </div>
                                                </div>
                                            </Dialog>
                                        </div>
                                    </Grid>
                                </Grid>
                            </Grid>
                        </Box>
                    </Paper>
                </NavigationMenu>
            </ThemeProvider>
            </>
           
        );
    }
}
const formStyles: React.CSSProperties = {
    width: '100%',
    margin: '0 auto',
};

const formRowStyles: React.CSSProperties = {
    marginBottom: '20px',
};

const halfWidthContainerStyles: React.CSSProperties = {
    display: 'flex',
    justifyContent: 'space-between',
};

const halfWidthStyles: React.CSSProperties = {
    width: '48%',
};
const invalidDetailsStyle: React.CSSProperties = {
    color: 'red',
};

const webStyles = {
    root: {
        color: '#000',
        fontFamily: 'Corbel v2',
        fontSize: '18px',
        fontStyle: 'normal',
        fontWeight: 700,
        lineHeight: 'normal',
        flexGrow: 1,
        backgroundColor: theme.palette.background.paper,
    },
    formControl: {
        margin: theme.spacing(3),
    },
    textClass: {
        color: '#000',
        fontFamily: 'Corbel V2',
        fontSize: '18px',
        fontStyle: 'normal',
        fontWeight: 700,
        lineHeight: 'normal',
        letterSpacing: '-0.198px',
    },
    checkborder: {
        border: '2px solid green'
    },
    display: {
        display: 'flex',
        marginTop: '20px',
        marginBottom: '15px',
        border: '1px solid black',
        borderRadius: '10px',
        '@media (max-width: 575px)': {
            flexWrap: 'wrap',
        }
    },
    setWidth: {
        height: 'calc(100vh - 170px)',
        overflowX: 'hidden' as const,
        padding: '0 15px 0 0',
        '& p': {
            color: '#000',
            fontFamily: 'Corbel V2',
            fontSize: '18px',
            fontStyle: 'normal',
            fontWeight: 'bold',
            lineHeight: 'normal',
            letterSpacing: '-0.198px'
        },
        '& span': {
            color: '#000',
            fontFamily: 'Corbel V2',
            fontSize: '16px',
            fontStyle: 'normal',
            fontWeight: '400',
            lineHeight: 'normal'
        },
        '&::-webkit-scrollbar': {
            width: '5px',
        },
        '&::-webkit-scrollbar-track': {
            borderRadius: '10px',
            backgroundColor: 'transparent',
        },
        '&::-webkit-scrollbar-thumb': {
            borderRadius: '10px',
            background: 'rgb(32, 94, 131, 0.5)',
        },
        '@media (max-width: 1280px)': {
            height: 'auto'
        }
    },
    maindiv: {
        padding: '40px',
        background: '#F6F8FA',
        height: 'calc(100vh - 110px)',
        overflow: 'hidden' as const,
        '&::-webkit-scrollbar': {
            width: '5px',
        },
        '&::-webkit-scrollbar-track': {
            borderRadius: '10px',
            backgroundColor: 'transparent',
        },
        '&::-webkit-scrollbar-thumb': {
            borderRadius: '10px',
            background: 'rgb(32, 94, 131, 0.5)',
        },
        '@media (max-width: 1280px)': {
            overflow: 'auto' as const,
        },
        '@media (max-width: 575px)': {
            height: 'calc(100vh - 150px)',
        },
    },
    imagecss: {
        borderRadius: '10px 0px 0px 10px'
    },
    imageBlock: {
        '@media (max-width: 575px)': {
            width: '100% !important'
        }
    },
    setitems: {
        display: 'flex',
        padding: '16px 32px',
        flexDirection: 'column' as const,
        alignItems: 'flex-start',
        gap: '10px',
        width: '70%',
        '@media (max-width: 767px)': {
            padding: '16px 20px',
        },
        '@media (max-width: 575px)': {
            width: '100%',
        },
        '& p': {
            '& span': {
                color: 'var(--basic-black, #000)',
                fontFamily: 'Corbel V2',
                fontSize: '18px',
                fontStyle: 'normal',
                fontWeight: '700',
                lineHeight: 'normal'
            },
            color: '#000',
            fontFamily: 'Corbel V2',
            fontSize: '18px',
            fontStyle: 'normal',
            fontWeight: 'bold',
            lineHeight: 'normal'
        },
        '& span': {
            color: 'rgba(0, 0, 0, 0.60)',
            fontFamily: 'Corbel V2',
            fontSize: '14px',
            fontStyle: 'normal',
            fontWeight: '400',
            lineHeight: 'normal'
        }
    },
    spancss: {
        color: 'var(--basic-black, #000)',
        fontFamily: 'Corbel V2',
        fontSize: '14px',
        fontStyle: 'normal',
        fontWeight: 700,
        lineHeight: 'normal',
    },
    setitems1: {
        display: 'flex',
        justifyContent: 'space-between',
        alignItems: 'flex-start',
        alignSelf: 'stretch',
        '& p': {
            color: 'var(--basic-black, #000)',
            fontFamily: 'Corbel V2',
            fontSize: '24px',
            fontStyle: 'normal',
            fontWeight: 'bold',
            lineHeight: '26px'
        },
        '& span': {
            color: 'var(--Primary-Button-Color, #37657F)',
            textAlign: 'right',
            fontFamily: 'Corbel V2',
            fontSize: '16px',
            fontStyle: 'normal',
            fontWeight: '400',
            lineHeight: '24px',
            textDecorationLine: 'underline'
        },
    },
    setitems2: {
        display: 'flex',
        justifyContent: 'space-between',
        alignItems: 'flex-start',
        alignSelf: 'stretch',
        '& p': {
            color: '#37657F',
            fontFamily: 'Corbel V2',
            fontSize: '16px',
            fontStyle: 'normal',
            fontWeight: '400',
            lineHeight: 'normal'
        },
        '& span': {
            color: '#37657F',
            textAlign: 'right',
            fontFamily: 'Corbel V2',
            fontSize: '18px',
            fontStyle: 'normal',
            fontWeight: 'bold',
            lineHeight: 'normal'
        },
    },
    setitems3: {
        display: 'flex',
        justifyContent: 'space-between',
        alignItems: 'flex-start',
        alignSelf: 'stretch',
        '& p': {
            color: '#37657F',
            fontFamily: 'Corbel V2',
            fontSize: '16px',
            fontStyle: 'normal',
            fontWeight: '400',
            lineHeight: 'normal'
        },
        '& span': {
            color: '#37657F',
            textAlign: 'right',
            fontFamily: 'Corbel V2',
            fontSize: '18px',
            fontStyle: 'normal',
            fontWeight: 'bold',
            lineHeight: 'normal'
        },
    },
    setitems4: {
        display: 'flex',
        justifyContent: 'space-between',
        alignItems: 'flex-start',
        alignSelf: 'stretch',
        '& p': {
            color: '#37657F',
            fontFamily: 'Corbel V2',
            fontSize: '16px',
            fontStyle: 'normal',
            fontWeight: '400',
            lineHeight: 'normal'
        },
        '& span': {
            color: '#37657F',
            textAlign: 'right',
            fontFamily: 'Corbel V2',
            fontSize: '18px',
            fontStyle: 'normal',
            fontWeight: 'bold',
            lineHeight: 'normal'
        },
    },
    setflexitems: {
        display: 'flex',
        justifyContent: 'space-between',
        alignItems: 'flex-start',
        alignSelf: 'stretch',
        flexWrap: 'wrap' as const,
        '& p': {
            display: 'flex',
            alignItems: 'center',
            gap: 8,
            '& span': {
                color: 'rgba(0, 0, 0, 0.60)',
                fontFamily: 'Corbel V2',
                fontSize: '15px',
                fontStyle: 'normal',
                fontWeight: '400',
                lineHeight: 'normal'
            }
        }
        ,
    },
    designflex: {
        display: 'flex',
        justifyContent: 'space-between',
        alignItems: 'center',
        borderRadius: '4px',
        borderLeft: '4px solid var(--Primary-Button-Color, #37657F)',
        background: 'var(--neutrals-cool-gray-200, #E2E8F0)',
        padding: '12px 8px 12px 16px',
        margin: '10px 0px',
        '& p': {
            color: '#37657F',
            fontFamily: 'Corbel V2',
            fontSize: '14px',
            fontStyle: 'normal',
            fontWeight: '400',
            lineHeight: '22px'
        }
    },
    designflex1: {
        display: 'flex',
        justifyContent: 'space-between',
        alignItems: 'center',
        borderRadius: '4px',
        borderLeft: '4px solid #DC2626',
        background: 'var(--status-red-100, #FEE2E2)',
        padding: '12px 16px',
        margin: '10px 0px',
        '& p': {
            color: '#DC2626',
            fontFamily: 'Corbel V2',
            fontSize: '12px',
            fontStyle: 'normal',
            fontWeight: '400',
            lineHeight: '18px'
        }
    },
    designBorder: {
        display: 'flex',
        alignItems: 'center',
        gap: '8px',
        color: '#37657F',
    },
    tabpaneclass: {
        borderRadius: '10px',
        background: 'var(--neutrals-cool-gray-200, #E2E8F0)',
        padding: '10px',
    },
    tabFlex: {
        width: '100%',
        '@media (max-width: 420px)': {
            display: 'block !important'
        },
        '& li': {
            minWidth: '33.33%'
        }
    },
    tabone: {
        display: 'flex',
        height: '40px',
        padding: '10px 35px',
        justifyContent: 'center',
        alignItems: 'center',
        gap: '16px',
        borderRadius: '10px',
        background: 'var(--Primary-Button-Color, #37657F)',
        boxShadow: '0px 8px 32px 0px rgba(0, 0, 0, 0.06), 0px 4px 8px 0px rgba(0, 0, 0, 0.03)',
        fontFamily: 'Corbel',
        fontSize: '16px',
        fontStyle: 'normal',
        fontWeight: 700,
        lineHeight: '24px',
    },
    spaceUp: {
        marginTop: '8px',
        color: '#000',
        fontFamily: 'Corbel V2',
        fontSize: '16px',
        fontStyle: 'normal',
        fontWeight: 'bold' as const,
        lineHeight: 'normal',
    },
    spaceUp1: {
        marginTop: '8px',
        color: '#0F172A',
        cursor: 'pointer',
        fontFamily: 'Corbel V2',
        fontSize: '14px',
        fontStyle: 'italic',
        fontWeight: 'bold' as const,
        lineHeight: 'normal',
        textDecorationLine: 'underline'
    },
    bankdetails: {
        '& span': {
            color: '#000',
            fontFamily: 'Corbel V2',
            fontSize: '16px',
            fontStyle: 'normal',
            fontWeight: 'bold',
            lineHeight: '24px',
        },
        '& p': {
            color: '#000',
            fontFamily: 'Corbel V2',
            fontSize: '16px',
            fontStyle: 'normal',
            fontWeight: '400',
            lineHeight: 'normal',
        }
    },
    tabButton: {
        padding: '10px 58px',
    },
    setUpload: {
        display: 'flex',
        padding: '8px 4px 8px 8px',
        alignItems: 'center',
        justifyContent: 'space-between',
        gap: '16px',
        background: 'var(--basic-white, #FFF)',
        boxShadow: '0px 8px 32px 0px rgba(0, 0, 0, 0.06), 0px 4px 8px 0px rgba(0, 0, 0, 0.03)'
    },
    uploadFlex: {
        display: 'flex',
        alignItems: 'center',
        flexWrap: 'wrap' as const,
        gap: 16,
        '& span': {
            color: '#000',
            fontFamily: 'Corbel V2',
            fontSize: '16px',
            fontStyle: 'normal',
            fontWeight: '400',
            lineHeight: '24px',
        }
    },
    setUploadImage: {
        display: 'flex',
        padding: '16px',
        alignItems: 'center',
        justifyContent: 'center',
        gap: '10px',
        borderRadius: '8px',
        background: 'var(--neutrals-cool-gray-300, #CBD5E1)',
        position: 'relative' as const,
        lineHeight: 56,
        width: 56,
        height: 56,
        '& form': {
            margin: 0
        },
        '& input': {
            position: 'absolute' as const,
            top: 0,
            left: 0,
            width: '56',
            height: '56',
            opacity: 0,
            cursor: 'pointer'
        },
    },
    setUploadText: {
        display: 'flex',
        padding: '6px',
        alignItems: 'flex-start',
        gap: '30px',
    },
    submitBtn: {
        '& span': {
            color: '#FFF',
            fontFamily: 'Corbel V2',
            fontSize: '16px',
            fontStyle: 'normal',
            fontWeight: 'bold',
            lineHeight: '24px',
            textTransform: 'capitalize'
        },
        display: 'flex',
        marginTop: '30px',
        width: '100%',
        height: '56px',
        padding: '16px',
        justifyContent: 'center',
        alignItems: 'center',
        gap: '8px',
        borderRadius: '8px',
        background: 'var(--Primary-Button-Color, #37657F)',
        "&:hover": {
            background: 'var(--Primary-Button-Color, #37657F)',
            color: 'var(--basic-white, #FFF)',
        },
    },
    setMediaQuery: {
        '@media (min-width: 960px)': {
            maxWidth: '100%'
        },
    },
    formContent: {
        marginTop: '15px',
        '& p': {
            marginTop: '13px'
        },
        '& input': {
            marginTop: '12px'
        }
    },
    dateCvv: {
        display: 'flex',
        width: '100%',
        flexWrap: 'wrap' as const,
        flexDirection: 'row' as const,
        alignItems: 'flex-start',
        gap: '8px',
        '& div': {
            flex: '0 0 calc(50% - 4px)'
        }
    },
    paymentModalImage: {
        display: 'flex',
        width: '56px',
        height: '56px',
        padding: '10px',
        justifyContent: 'center',
        alignItems: 'center',
        gap: '10px',
        borderRadius: '10px',
        background: 'var(--neutrals-cool-gray-100, #F1F5F9)'
    },
    dashedDividerStyle: {
        margin: '20px 0',
        borderBottom: '2px dashed #94A3B8',
        background: 'transparent',
        height: 1
    },
    amountClass: {
        display: 'flex',
        flexDirection: 'column' as const,
        justifyContent: 'center',
        '& p': {
            color: 'var(--neutrals-cool-gray-700, #334155)',
            fontFamily: 'Corbel V2',
            fontSize: '16px',
            fontStyle: 'normal',
            fontWeight: '400',
            lineHeight: 'normal'
        },
        '& span': {
            color: 'var(--neutrals-cool-gray-700, #334155)',
            fontFamily: 'Corbel V2',
            fontSize: '16px',
            fontStyle: 'normal',
            fontWeight: 'bold',
            lineHeight: 'normal'
        },
    },
    setAmount: {
        display: 'flex',
        justifyContent: 'space-between',
        alignItems: 'center',
    },
    mainDialog: {
        '& .modal-footer .btn': {
            '@media (max-width: 768px)': {
                maxWidth: '100%'
            },
        }
    },
    submitBtnStripe: {
        display: 'flex',
        marginTop: '30px',
        width: '100%',
        height: '56px',
        padding: '16px',
        justifyContent: 'center',
        alignItems: 'center',
        gap: '8px',
        borderRadius: '8px',
        background: 'var(--Primary-Button-Color, #37657F)',
        color: '#FFF',
        fontFamily: 'Corbel V2',
        fontSize: '16px',
        fontStyle: 'normal',
        lineHeight: '24px',
        '&:hover': {
            background: 'var(--Primary-Button-Color, #37657F)',
            color: 'var(--basic-white, #FFF)',
        },
    },
    cardElementContainerStyles: {
        minHeight: '55px',
        borderRadius: '8px',
        border: '1px solid #ccc',
        padding: '12px',
        display: 'block',
        alignItems: 'center',
        color: '#424770',
        fontFamily: 'Source Code Pro, monospace',
        letterSpacing: '0.025em',
        fontSize: '16px',
        '::placeholder': {
            color: '#aab7c4',
        },
    },
    payment_proof_modal_arabic: {
        "& .modal-header": {
            textAlign: "left"
        },
        "& .modal-body": {
            textAlign: "right"
        },
        "& .modal-body .modal-icon": {
            marginLeft: "auto !important"
        },
        "& .set_ammount": {
            flexDirection: "row-reverse"
        },
        "& .modal-footer": {
            flexDirection: "row-reverse"
        },
    },
    creditDebitWrap: {
        "& .wpwl-form-card .wpwl-group-expiry .wpwl-control-expiry": {
            minWidth: "100%",
        },
        "& input": {
            minHeight: "31px !important",
            color: "#37657F !important",
            fontFamily: 'Source Code Pro, monospace',
        },
        "& select": {
            minHeight: "31px !important",
        },
    },

}

export default withStyles(webStyles)(Payments)
